import React from "react";
import TemplateContainer from "../../common/TemplateContainer";
import AdditionalContextInput from "./AdditionalContextInput";
import ArticleByInput from "./ArticleByInput";
import CreativityLevelSelector from "./CreativityLevelSelector";
import IncludeCreditsToggle from "./IncludeCreditsToggle";
import TranscribeSubjectSelector from "./TranscribeSubjectSelector";

const AdditionalSettings = () => {
  return (
    <div className="space-y-6 animate-fadeIn">
      <TemplateContainer>
        <IncludeCreditsToggle />
        <ArticleByInput />
      </TemplateContainer>
      <TemplateContainer className="flex flex-col gap-0">
        <AdditionalContextInput />
        <TranscribeSubjectSelector />
        <CreativityLevelSelector />
      </TemplateContainer>
    </div>
  );
};

export default AdditionalSettings;
