import React from "react";

import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";

import { ROUTE_TEMPLATES } from "../../constants/routes";
import { TemplateStatusEnum, TemplateStepEnum } from "../../enums/template";

import SpringContainer from "../../components/containers/SpringContainer";
import Button from "../../components/form/Button";
import AdvancedSettings from "./components/advancedSettings/AdvancedSettings";
import SideNav from "./components/common/SideNav";
import DestinationSelector from "./components/destination/DestinationSelector";
import Setup from "./components/setup/Setup";
import { useTemplateForm } from "./hooks/useTemplateForm";

const TemplateForm = () => {
  const navigate = useNavigate();

  const {
    currentStep,
    setCurrentStep,
    template,
    selectedAccount,
    isLoading,
    isCreate,
    handleValidation,
    handleSubmit,
  } = useTemplateForm();

  if (!selectedAccount?.account_id || !template) {
    return null;
  }

  return (
    <SpringContainer className="overflow-y-auto overflow-x-hidden">
      <Formik
        initialValues={template}
        enableReinitialize={true}
        validateOnChange={false}
        validate={handleValidation}
        onSubmit={handleSubmit}
      >
        {({ values, validateForm, setFieldValue, submitForm }) => {
          const isAuto = values?.is_auto_enabled;
          const isDraft =
            values?.template_status_id === TemplateStatusEnum.DRAFT;
          const isQueued =
            values?.template_status_id === TemplateStatusEnum.QUEUED;

          const validateAndNavigate = (
            e: React.MouseEvent,
            nextStep: TemplateStepEnum
          ) => {
            e.stopPropagation();
            validateForm().then(handleValidationResult);

            function handleValidationResult(
              formErrors: Record<string, string>
            ) {
              if (Object.keys(formErrors).length === 0) {
                setCurrentStep(nextStep);
              }
            }
          };

          const updateStatusAndTriggerSubmit = (status: TemplateStatusEnum) => {
            setFieldValue("template_status_id", status);
            submitForm();
          };

          return (
            <Form>
              <div className="flex flex-col xl:flex-row xl:space-x-6">
                <SideNav {...{ currentStep, setCurrentStep }} />
                <div
                  className={`w-full ltr:mr-2 rtl:ml-2 mt-10 ${isLoading ? "opacity-50" : "opacity-100"}`}
                >
                  {currentStep === TemplateStepEnum.SETUP && <Setup />}
                  {currentStep === TemplateStepEnum.ADVANCED_SETTINGS && (
                    <AdvancedSettings />
                  )}
                  {currentStep === TemplateStepEnum.DESTINATION && (
                    <DestinationSelector />
                  )}
                  <div className="flex flex-col gap-6 my-6">
                    {currentStep === TemplateStepEnum.ADVANCED_SETTINGS && (
                      <Button
                        paddingZero
                        variant="transparent-primary"
                        onClick={(event: React.MouseEvent) =>
                          validateAndNavigate(
                            event,
                            TemplateStepEnum.DESTINATION
                          )
                        }
                        className="min-w-24 w-max"
                      >
                        <span className="flex items-center uppercase text-xs font-semibold tracking-wide">
                          Destination{" "}
                          <ChevronRightIcon className="ml-0.5 text-primary size-4" />
                        </span>
                      </Button>
                    )}
                    {currentStep === TemplateStepEnum.SETUP && (
                      <Button
                        paddingZero
                        variant="transparent-primary"
                        onClick={(event: React.MouseEvent) =>
                          validateAndNavigate(
                            event,
                            TemplateStepEnum.ADVANCED_SETTINGS
                          )
                        }
                        className={classNames("min-w-24 w-max")}
                      >
                        <span className="flex items-center uppercase text-xs font-semibold tracking-wide">
                          Advanced Settings{" "}
                          <ChevronRightIcon className="ml-0.5 text-primary size-4" />
                        </span>
                      </Button>
                    )}
                    {isQueued && (
                      <div className="text-xs text-red-600 uppercase tracking-wide flex gap-1">
                        <ExclamationTriangleIcon className="size-4 text-red-600" />
                        This template is currently generating.
                      </div>
                    )}
                    <div className="flex items-center gap-3">
                      {(isAuto || isCreate || isDraft) && (
                        <Button
                          variant={isQueued ? "disabled" : "primary"}
                          onClick={() => {
                            updateStatusAndTriggerSubmit(
                              isAuto
                                ? TemplateStatusEnum.PENDING
                                : TemplateStatusEnum.DRAFT
                            );
                          }}
                          loading={isLoading}
                          style={{ minWidth: 120 }}
                        >
                          {isAuto ? "Save" : "Save as Draft"}
                        </Button>
                      )}
                      {!isAuto && (
                        <Button
                          variant={isQueued ? "disabled" : "primary"}
                          onClick={() => {
                            updateStatusAndTriggerSubmit(
                              TemplateStatusEnum.PENDING
                            );
                          }}
                          loading={isLoading}
                          style={{ minWidth: 150 }}
                        >
                          Generate Now
                        </Button>
                      )}
                      <Button
                        variant="transparent"
                        onClick={(e: any) => {
                          e.stopPropagation();
                          navigate(ROUTE_TEMPLATES);
                        }}
                        loading={isLoading}
                        style={{ minWidth: 90 }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </SpringContainer>
  );
};

export default TemplateForm;
