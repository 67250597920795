import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../../../components/common/Modal";
import Button from "../../../components/form/Button";
import { ROUTE_DESTINATIONS } from "../../../constants/routes";
import useSelectedAccount from "../../../hooks/useSelectedAccount";
import { useDeleteDestinationByIdMutation } from "../../../redux/services/destinations";

const DestinationDeleteModal = ({ destinationId, onRequestClose }: any) => {
  const navigate = useNavigate();
  const { accountId } = useSelectedAccount();
  const [deleteDestinationById, { isLoading, isSuccess }] =
    useDeleteDestinationByIdMutation();

  useEffect(() => {
    if (isSuccess) {
      onRequestClose();
      navigate(ROUTE_DESTINATIONS);
    }
  }, [isSuccess, navigate, onRequestClose]);

  const handleDelete = async () => {
    await deleteDestinationById({
      account_id: accountId,
      destination_id: destinationId,
    })
      .unwrap()
      .catch(() => {});
  };

  return (
    <Modal
      className="p-8"
      onRequestClose={onRequestClose}
      isOpen={true}
    >
      <div>
        <div className="text-xl mb-4 text-gray-800">Delete Destination</div>
        <div className="text-gray-500 mb-8">Are sure you want to delete?</div>
        <div className="mt-5">
          <div className="space-x-4">
            <Button
              variant="danger"
              loading={isLoading}
              className="w-20"
              onClick={(e: any) => {
                e.stopPropagation();
                handleDelete();
              }}
            >
              Delete
            </Button>
            <Button
              variant="transparent"
              onClick={(e: any) => {
                e.stopPropagation();
                onRequestClose();
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DestinationDeleteModal;
