import React from "react";
import Button from "../../../components/form/Button";
import {
  RunStatusColorMap,
  RunStatusMap,
  TemplateStatusEnum,
} from "../../../enums/template";

const StatusColor = ({ status }: { status: TemplateStatusEnum }) => (
  <div
    className="w-2 h-2 rounded-full"
    style={{
      background: RunStatusColorMap[status],
    }}
  />
);

const TemplateStatus = ({
  status,
  linkToArticles,
}: {
  status: TemplateStatusEnum;
  linkToArticles: () => void;
}) => {
  if (status === TemplateStatusEnum.COMPLETED) {
    return (
      <Button
        variant="transparent-primary"
        paddingZero
        className="flex items-center gap-1.5"
        onClick={(e: any) => {
          e.stopPropagation();
          linkToArticles();
        }}
      >
        <StatusColor status={status} />
        {RunStatusMap[status]}
      </Button>
    );
  }
  return (
    <>
      <StatusColor status={status} />
      {RunStatusMap[status]}
    </>
  );
};

export default TemplateStatus;
