import classNames from "classnames";
import React, { CSSProperties } from "react";
import { PlacesType, Tooltip } from "react-tooltip";
import LoadingSpinner from "../loaders/LoadingSpinner";

export type ButtonVariant =
  | "default"
  | "disabled"
  | "primary"
  | "secondary"
  | "danger"
  | "transparent"
  | "transparent-primary"
  | "transparent-danger"
  | "icon"
  | "light-primary"
  | "light-danger";

type ButtonProps = {
  id?: string;
  type?: "button" | "submit" | "reset";
  fullWidth?: boolean;
  variant?: ButtonVariant;
  className?: string;
  loading?: boolean;
  onClick: any;
  children: any;
  tooltip?: string;
  paddingZero?: boolean;
  tooltipPosition?: PlacesType;
  style?: CSSProperties;
};
const Button: React.FC<ButtonProps> = ({
  variant,
  className,
  loading,
  onClick,
  children,
  tooltip,
  fullWidth,
  id = `button-${Math.random().toFixed(3)}`,
  type = "button",
  paddingZero = false,
  tooltipPosition = "right",
  style,
  ...rest
}) => {
  const button = () => {
    if (variant === "icon") {
      return (
        <button
          {...rest}
          data-tooltip-id={id}
          data-tooltip-content={tooltip}
          type="button"
          className={classNames(
            "border-0 rounded hover:bg-gray-100 flex items-center justify-center text-gray-500",
            { "p-1.5": !paddingZero },
            { "p-0": paddingZero },
            className
          )}
          onClick={onClick}
        >
          {children}
        </button>
      );
    }
    return (
      <button
        {...rest}
        id={id ?? "button"}
        data-tooltip-id={id}
        data-tooltip-content={tooltip}
        type={type}
        className={classNames(
          "rounded text-sm",
          { "block w-full": fullWidth },
          { "px-4 py-2": !paddingZero },
          { "p-0": paddingZero },
          {
            "bg-gray-200 text-gray-500 border-0 cursor-not-allowed":
              variant === "disabled",
          },
          {
            "text-gray-500 hover:border-gray-200 hover:bg-gray-50":
              variant === "default",
          },
          {
            "bg-primary text-white border-0 hover:bg-primary/90":
              variant === "primary",
          },
          {
            "bg-primary/10 text-primary hover:bg-primary/20":
              variant === "secondary",
          },
          {
            "bg-red-600 text-white border-0 hover:bg-red-700":
              variant === "danger",
          },
          {
            "text-gray-500 bg-transparent hover:text-gray-600":
              variant === "transparent",
          },
          {
            "text-primary bg-transparent hover:text-primary/90":
              variant === "transparent-primary",
          },
          {
            "text-red-500 bg-transparent hover:text-red-500/90":
              variant === "transparent-danger",
          },
          {
            "text-primary bg-transparent hover:bg-primary/10":
              variant === "light-primary",
          },
          {
            "text-red-500 bg-transparent hover:bg-red-100":
              variant === "light-danger",
          },
          className
        )}
        style={style}
        disabled={variant === "disabled"}
        onClick={(e) => {
          if (variant !== "disabled") {
            onClick(e);
          }
        }}
      >
        {loading ? <LoadingSpinner /> : children}
      </button>
    );
  };

  return (
    <>
      {tooltip && (
        <Tooltip
          id={id}
          place={tooltipPosition}
          delayShow={500}
        />
      )}
      {button()}
    </>
  );
};

export default Button;
