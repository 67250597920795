import React from "react";
import "./ArticleGeneratingSpinner.css";

const ArticleGeneratingSpinner = () => (
  <div className="spinner-container">
    <div className="spinner">
      {Array.from({ length: 12 }).map((_, index) => (
        <div key={index}></div>
      ))}
    </div>
  </div>
);

export default ArticleGeneratingSpinner;
