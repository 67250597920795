import { useFormikContext } from "formik";
import React from "react";
import { ImageSizeEnum } from "../../../../enums/template";
import { Template } from "../../../../models";
import SwitchContainer from "../common/SwitchContainer";

export default function IncludeImageToggle() {
  const { values, setFieldValue } = useFormikContext<Partial<Template>>();
  return (
    <SwitchContainer
      title="Include Image"
      subTitle="Add an image to the article."
      tooltip="A unique, non-stock image will be generated based on the content of the article."
      enabled={Boolean(values?.include_image)}
      setEnabled={() => {
        setFieldValue("include_image", !values?.include_image);
        if (!values?.include_image && !values.image_size_id) {
          setFieldValue("image_size_id", ImageSizeEnum.LANDSCAPE);
        }
      }}
    />
  );
}
