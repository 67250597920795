import React from "react";
import TemplateContainer from "../../common/TemplateContainer";
import BrandPersonaSelector from "./BrandPersonaSelector";
import BrandToneSelector from "./BrandToneSelector";
import BrandVoiceSelector from "./BrandVoiceSelector";

const BrandSettings = () => {
  return (
    <div className="space-y-6 animate-fadeIn">
      <TemplateContainer>
        <BrandPersonaSelector />
        <BrandVoiceSelector />
        <BrandToneSelector />
      </TemplateContainer>
    </div>
  );
};

export default BrandSettings;
