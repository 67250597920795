import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Message from "../../components/common/Message";
import Modal from "../../components/common/Modal";
import Button from "../../components/form/Button";
import Input from "../../components/form/Input";
import LoadingSpinner from "../../components/loaders/LoadingSpinner";
import { ROUTE_LOGIN } from "../../constants/routes";
import { useAppDispatch } from "../../redux/hooks";
import { useDeleteUserMutation } from "../../redux/services/user";
import { logout } from "../../redux/slices/authSlice";

const DELETE_WARNING =
  "Are you sure you want to delete your account? All data related to this account will be deleted. This action is permanent and unrecoverable.";
const DeleteProfileModal = ({
  onRequestClose,
}: {
  onRequestClose: () => void;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [deleteUser, { isLoading }] = useDeleteUserMutation();
  const [confirmDeleteText, setConfirmDeleteText] = useState("");
  const enable = confirmDeleteText === "delete account";
  const [errorMessage, setErrorMessage] = useState("");

  const handleDelete = async () => {
    await deleteUser()
      .unwrap()
      .then((response: any) => {
        dispatch(logout());
        navigate(ROUTE_LOGIN, { replace: true });
      })
      .catch((error: any) => {
        setErrorMessage(error || "Something went wrong. Please try again.");
      });
  };
  return (
    <Modal
      className="p-8"
      width={600}
      onRequestClose={onRequestClose}
      isOpen={true}
    >
      <div>
        <div className="text-2xl mb-4 text-gray-800">Delete Account</div>
        <div className="text-red-500 mb-8">{DELETE_WARNING}</div>
        <label className="text-sm tracking-wide text-gray-700">
          Please enter <b>delete account</b> to confirm for deletion.
        </label>
        <Input
          value={confirmDeleteText}
          onChange={(e) => setConfirmDeleteText(e.target.value)}
        />
        <div className="mt-5">
          <Message
            type="error"
            message={errorMessage}
          />
          <div className="space-x-4">
            <Button
              variant="danger"
              onClick={() => {
                if (enable) {
                  handleDelete();
                }
              }}
            >
              {isLoading ? <LoadingSpinner size="18px" /> : "Delete"}
            </Button>
            <Button
              variant="transparent"
              onClick={onRequestClose}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteProfileModal;
