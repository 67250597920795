import {
  AdjustmentsHorizontalIcon,
  ArrowUpOnSquareIcon,
  Cog6ToothIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import { useFormikContext } from "formik";
import React from "react";
import { Heading } from "../../../../components/common/Typography";
import { TemplateStepEnum } from "../../../../enums/template";
import { Template } from "../../../../models";
import TemplateSideNavItem from "./TemplateSideNavItem";

export const Steps = [
  {
    name: TemplateStepEnum.SETUP,
    title: "Setup",
    icon: <AdjustmentsHorizontalIcon className="w-5 mr-1" />,
  },
  {
    name: TemplateStepEnum.ADVANCED_SETTINGS,
    title: "Advanced Settings",
    icon: <Cog6ToothIcon className="w-5 mr-1" />,
  },
  {
    name: TemplateStepEnum.DESTINATION,
    title: "Destination",
    icon: <ArrowUpOnSquareIcon className="w-5 mr-1" />,
  },
];

const SideNav = ({ currentStep, setCurrentStep }: any) => {
  const { validateForm } = useFormikContext<Partial<Template>>();
  const handleTabClick = (step: TemplateStepEnum) => {
    validateForm().then((formErrors: any) => {
      if (Object.keys(formErrors).length === 0) {
        setCurrentStep(step);
      }
    });
  };
  return (
    <>
      <div className="hidden xl:block">
        <nav
          className="no-scrollbar flex flex-col gap-2 overflow-scroll primary-navigation sticky top-0 -mt-7"
          aria-label="Tabs"
        >
          <Heading className="mb-2">Create Article Template</Heading>
          {Steps.map((props) => (
            <TemplateSideNavItem
              key={props.name}
              current={props.name === currentStep}
              onClick={(e) => {
                e.stopPropagation();
                handleTabClick(props.name);
              }}
              {...props}
            />
          ))}
        </nav>
      </div>
      <div className="p-2 md:mx-0 md:p-0 xl:hidden">
        <div className="mb-4 h-9 max-w-full lg:mb-5">
          <Heading className="mb-4">Create Article Template</Heading>
          <nav
            className="no-scrollbar flex gap-2 overflow-x-scroll rounded-md"
            aria-label="Tabs"
          >
            {Steps.map((props) => {
              const current = props?.name === currentStep;
              return (
                <button
                  type="button"
                  id={props?.name}
                  key={props?.name}
                  className={classNames(
                    "border p-2 hover:border-transparent hover:bg-gray-100 text-sm text-gray-600 rounded",
                    {
                      "border-transparent bg-gray-100 font-semibold": current,
                    },
                    {
                      "border-transparent": !current,
                    }
                  )}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    handleTabClick(props.name);
                  }}
                >
                  <div className="flex items-center justify-center whitespace-nowrap transition-all ease-linear">
                    {props.icon as any}
                    {props.title}
                  </div>
                </button>
              );
            })}
          </nav>
        </div>
      </div>
    </>
  );
};

export default SideNav;
