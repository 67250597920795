import {
  AdjustmentsHorizontalIcon,
  ArrowUpOnSquareIcon,
  ChartBarSquareIcon,
  CreditCardIcon,
  DocumentCheckIcon,
  DocumentPlusIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ROUTE_ARTICLES,
  ROUTE_ARTICLE_CREATE,
  ROUTE_BILLING,
  ROUTE_DESTINATIONS,
  ROUTE_PROFILE,
  ROUTE_ROOT,
  ROUTE_TEMPLATES,
} from "../../constants/routes";
import AccountDropdown from "../features/AccountDropdown";

const navigation = [
  { name: "Dashboard", href: ROUTE_ROOT, icon: ChartBarSquareIcon },
  {
    name: "Create Article",
    href: ROUTE_ARTICLE_CREATE,
    icon: DocumentPlusIcon,
  },
  { name: "Templates", href: ROUTE_TEMPLATES, icon: AdjustmentsHorizontalIcon },
  { name: "Completed Articles", href: ROUTE_ARTICLES, icon: DocumentCheckIcon },
  { name: "Destinations", href: ROUTE_DESTINATIONS, icon: ArrowUpOnSquareIcon },
  { name: "Profile", href: ROUTE_PROFILE, icon: UserCircleIcon },
  { name: "Billing", href: ROUTE_BILLING, icon: CreditCardIcon },
];

const Sidebar = ({ sidebarOpen }: { sidebarOpen: boolean }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <div
      className={classNames(
        "flex flex-col bg-white border-gray-200 flex-none transition-all duration-300",
        sidebarOpen ? "w-64" : "w-16"
      )}
    >
      <div className="relative z-50 flex h-full grow flex-col gap-y-5 overflow-visible border-r border-gray-200 bg-gray-50 no-scrollbar px-4">
        <div className="mt-4 w-full">
          <AccountDropdown sidebarOpen={sidebarOpen} />
        </div>
        <nav className="flex flex-1 flex-col">
          <ul className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul className="space-y-2">
                {navigation.map((item) => {
                  return (
                    <li key={item.name}>
                      <button
                        onClick={() => navigate(item.href)}
                        className={classNames(
                          item.href === pathname
                            ? "text-primary font-semibold bg-primary/10"
                            : "text-gray-900 hover:bg-gray-200",
                          "min-h-10.5 border border-transparent group flex items-center gap-x-3 h-10",
                          "rounded-md p-1 text-sm leading-6 transition-colors ease-in-out duration-100",
                          sidebarOpen ? "w-full" : "w-max"
                        )}
                      >
                        <item.icon
                          className={classNames(
                            "h-5 w-5 shrink-0",
                            item.href === pathname
                              ? "text-primary"
                              : "text-gray-900"
                          )}
                          aria-hidden="true"
                        />
                        {sidebarOpen && <span>{item.name}</span>}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
