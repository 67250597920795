import { useFormikContext } from "formik";
import React from "react";
import ChatGPTSVG from "../../../../../assets/svg/chatgpt.svg";
import RecraftSVG from "../../../../../assets/svg/recraft.svg";
import CustomSelect, {
  OptionType,
} from "../../../../../components/form/CustomSelect";
import { ImageEngineEnum } from "../../../../../enums/imageEngine";
import { Template } from "../../../../../models";
import TemplateContainer from "../../common/TemplateContainer";
import ImageExcludeFacesToggle from "./ImageExcludeFacesToggle";
import ImageExcludeTextToggle from "./ImageExcludeTextToggle";

const imageEngineOptions: OptionType[] = [
  {
    value: ImageEngineEnum.DALL_E_3,
    label: "DALL·E 3",
    description: "",
    icon: ChatGPTSVG,
  },
  {
    value: ImageEngineEnum.RECRAFT_V3,
    label: "Recraft V3",
    description: "",
    icon: RecraftSVG,
  },
];

export default function ImageEngineSelector() {
  const { values, setFieldValue } = useFormikContext<Partial<Template>>();
  return (
    <TemplateContainer
      isSuperUserFeature
      className="space-y-4"
    >
      <CustomSelect
        label="Image Engine"
        placeholder="-"
        options={imageEngineOptions}
        selected={imageEngineOptions.find(
          (option) => option.value === values?.image_engine_id
        )}
        setSelected={(option) => {
          setFieldValue(
            "image_engine_id",
            (option?.value as ImageEngineEnum) || null
          );
          if (option?.value === ImageEngineEnum.RECRAFT_V3) {
            setFieldValue("exclude_image_text", true);
            setFieldValue("exclude_image_faces", true);
          }
        }}
      />
      {values?.image_engine_id === ImageEngineEnum.RECRAFT_V3 && (
        <>
          <ImageExcludeTextToggle />
          <ImageExcludeFacesToggle />
        </>
      )}
    </TemplateContainer>
  );
}
