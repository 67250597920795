import { useFormikContext } from "formik";
import React from "react";
import Input from "../../../../../components/form/Input";
import { Template } from "../../../../../models";
import { getRandomCharacterQuote } from "../../../../../utils";

export default function ArticleByInput() {
  const { values, setFieldValue } = useFormikContext<Partial<Template>>();

  if (!values?.include_credits) return null;

  return (
    <div className="mt-6">
      <Input
        label="Article By"
        name="bio"
        value={values?.bio}
        placeholder={getRandomCharacterQuote()}
        tooltip='This will add "Article by" and whatever you put in the field to the bottom of the article.'
        onChange={({ target: { value } }) => setFieldValue("bio", value)}
      />
    </div>
  );
}
