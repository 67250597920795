import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import moment from "moment-timezone";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageHeading from "../../components/common/PageHeading";
import SpringContainer from "../../components/containers/SpringContainer";
import Button from "../../components/form/Button";
import { ROUTE_RENEW_PLANS } from "../../constants/routes";
import useSelectedAccount from "../../hooks/useSelectedAccount";
import { useAppSelector } from "../../redux/hooks";
import { useGetSubscriptionQuery } from "../../redux/services/subscription";
import { RootState } from "../../redux/store";
import { getStripeBillingURL } from "../../stripe";
import { formatDateUS, getDaysLeft } from "../../utils";

const Billing = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const authToken = useAppSelector((state: RootState) => state.auth.token);
  const { selectedAccount, accountId, timezone } = useSelectedAccount();
  const { data: subscriptionData } = useGetSubscriptionQuery(
    {
      account_id: accountId || "",
    },
    { skip: !accountId }
  );

  const manageBilling = async () => {
    if (selectedAccount?.stripe_customer_id && authToken) {
      setLoading(true);
      const billingURL = await getStripeBillingURL(
        selectedAccount.stripe_customer_id,
        authToken
      );
      setLoading(false);
      window.open(billingURL, "_blank");
    }
  };

  // TODO: Pass timezone in API request to get timezone adjusted dates
  const startDate = useMemo(
    () => moment.tz(subscriptionData?.current_period_start, timezone),
    [subscriptionData?.current_period_start, timezone]
  );
  const endDate = useMemo(
    () => moment.tz(subscriptionData?.current_period_end, timezone),
    [subscriptionData?.current_period_end, timezone]
  );
  const subscriptionDaysRemaining = useMemo(() => {
    const currentDate = moment().tz(timezone);
    return getDaysLeft(endDate, currentDate);
  }, [endDate, timezone]);

  /* TODO: If passing timezone in API, use this code
  const startDate = subscriptionData?.current_period_start;
  const endDate = subscriptionData?.current_period_end;
  const subscriptionDaysRemaining = useMemo(() => {
    const currentDate = moment().tz(timezone);
    return getDaysLeft(endDate, currentDate);
  }, [endDate, timezone]);
  */

  const renderSubscriptionMessage = () => {
    const status = subscriptionData?.status;
    if (!status) return null;

    if (status === "trialing") {
      return subscriptionDaysRemaining > 0 ? (
        <div className="mt-8">
          The trial ends in{" "}
          <b>
            {subscriptionDaysRemaining}{" "}
            {subscriptionDaysRemaining > 1 ? "days" : "day"}.
          </b>
        </div>
      ) : (
        <div className="mt-8">The trial has ended.</div>
      );
    } else if (
      status === "active" &&
      subscriptionData?.canceled_at &&
      subscriptionData?.cancel_at_period_end
    ) {
      return (
        <div className="mt-8">
          The plan ends on{" "}
          <b>{formatDateUS(subscriptionData?.current_period_end)}</b>.
        </div>
      );
    } else if (status === "active" && subscriptionDaysRemaining > 0) {
      return (
        <div className="mt-8">
          The billing cycle renews in{" "}
          <b>
            {subscriptionDaysRemaining}{" "}
            {subscriptionDaysRemaining > 1 ? "days" : "day"}.
          </b>
        </div>
      );
    } else if (status === "canceled" && subscriptionData?.ended_at) {
      return (
        <div className="mt-8">
          The plan ended on <b>{formatDateUS(subscriptionData.ended_at)}.</b>
        </div>
      );
    }

    return null;
  };

  return (
    <SpringContainer>
      <PageHeading heading="Billing" />

      <div className="border rounded-lg p-4 mt-4 max-w-sm">
        <div className="mb-1 text-sm">Current Plan</div>
        <div className="text-xl font-semibold flex items-center">
          {subscriptionData?.plan_name}
          {subscriptionData?.status && (
            <span className="ml-1 capitalize bg-green-200/50 p-0.5 px-2 rounded-sm text-xs text-green-900">
              {subscriptionData?.status}
            </span>
          )}
        </div>
        {subscriptionData?.status !== "canceled" && (
          <div className="text-gray-500 text-sm">
            {formatDateUS(startDate.toString(), false)} -{" "}
            {formatDateUS(endDate.toString())}
          </div>
        )}
        {renderSubscriptionMessage()}
      </div>

      {subscriptionData?.status === "canceled" ? (
        <Button
          variant="primary"
          onClick={() => navigate(ROUTE_RENEW_PLANS)}
          loading={loading}
          className="mt-6"
        >
          Renew Plan
        </Button>
      ) : (
        <Button
          variant="primary"
          onClick={() => manageBilling()}
          loading={loading}
          className="mt-6 min-w-36"
        >
          Manage Billing{" "}
          <ArrowTopRightOnSquareIcon className="size-4 inline-flex" />
        </Button>
      )}
      <Button
        variant="primary"
        onClick={() =>
          window.open("https://www.articlex.com/pricing/", "_blank")
        }
        className="mt-6 ml-4"
      >
        Pricing <ArrowTopRightOnSquareIcon className="size-4 inline-flex" />
      </Button>
    </SpringContainer>
  );
};

export default Billing;
