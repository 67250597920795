import {
  BoltIcon,
  ChevronRightIcon,
  ClipboardDocumentListIcon,
  CreditCardIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/outline";
import moment from "moment-timezone";
import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import BarChart from "../../components/charts/BarChart";
import PageHeading from "../../components/common/PageHeading";
import SpringContainer from "../../components/containers/SpringContainer";
import Button from "../../components/form/Button";
import {
  ROUTE_ARTICLES,
  ROUTE_PLANS,
  ROUTE_PROFILE,
} from "../../constants/routes";
import useSelectedAccount from "../../hooks/useSelectedAccount";
import { useGetAllArticlesQuery } from "../../redux/services/article";
import { useGetDashboardQuery } from "../../redux/services/dashboard";
import { useGetSubscriptionQuery } from "../../redux/services/subscription";
import { formatDateUS, formatNumberWithCommas, getDaysLeft } from "../../utils";
import ArticleTableContainer from "../Articles/components/ArticleTableContainer";
import { MetricCard } from "./components/MetricCard";

const Dashboard = () => {
  const navigate = useNavigate();
  const { accountId, timezone } = useSelectedAccount();
  const { data: dashboardData, isLoading: isDashboardLoading } =
    useGetDashboardQuery(
      {
        account_id: accountId,
        timezone,
      },
      { skip: !accountId }
    );

  const {
    data: subscriptionData,
    isLoading: isSubscriptionLoading,
    isUninitialized: isSubscriptionUninitialized,
  } = useGetSubscriptionQuery(
    {
      account_id: accountId,
    },
    { skip: !accountId }
  );

  const {
    data: articlesData,
    refetch: refetchArticles,
    isFetching: isFetchingArticles,
  } = useGetAllArticlesQuery(
    {
      account_id: accountId,
      offset: 0,
      limit: 3,
      include_total_count: true,
    },
    { skip: !accountId }
  );

  useEffect(() => {
    if (
      !isSubscriptionLoading &&
      !isSubscriptionUninitialized &&
      !subscriptionData
    ) {
      navigate(ROUTE_PLANS);
    }
  }, [
    isSubscriptionLoading,
    isSubscriptionUninitialized,
    subscriptionData,
    navigate,
  ]);

  const subscriptionDaysRemaining = useMemo(() => {
    const endDate = moment(dashboardData?.current_period_end);
    const currentDate = moment().tz(dashboardData?.timezone);
    return getDaysLeft(endDate, currentDate);
  }, [dashboardData?.current_period_end, dashboardData?.timezone]);

  if (isDashboardLoading || !dashboardData) {
    return null;
  }

  return (
    <SpringContainer className="overflow-y-auto overflow-x-hidden">
      <PageHeading heading="Overview" />
      <div className="my-4 grid grid-cols-1 md:grid-cols-2 gap-5 xl:grid-cols-4">
        <MetricCard
          icon={<CreditCardIcon className="size-6 text-blue-800" />}
          iconBackground="bg-blue-100/50"
          data={
            <div className="flex items-center gap-1 flex-wrap">
              <span>{dashboardData?.plan_name}</span>
              <span className="capitalize bg-green-200/50 p-0.5 px-2 rounded-sm text-xs text-green-900">
                {dashboardData?.status}
              </span>
            </div>
          }
          heading={
            <div className="flex gap-1 flex-wrap">
              <span>
                {formatDateUS(dashboardData?.current_period_start, false)}
              </span>
              <span>-</span>
              <span>{formatDateUS(dashboardData?.current_period_end)}</span>
              <span>
                (
                {subscriptionDaysRemaining >= 0 ? subscriptionDaysRemaining : 0}{" "}
                {subscriptionDaysRemaining > 1 ? "days" : "day"} left)
              </span>
            </div>
          }
        />
        <MetricCard
          icon={<CurrencyDollarIcon className="size-6 text-amber-500" />}
          iconBackground="bg-amber-100/50"
          heading="Tokens Remaining"
          data={formatNumberWithCommas(dashboardData?.tokens_remaining)}
        />
        <MetricCard
          icon={<ClipboardDocumentListIcon className="size-6 text-red-800" />}
          iconBackground="bg-red-100/50"
          heading="Articles"
          data={dashboardData.articles_this_period}
        />
        <MetricCard
          icon={<BoltIcon className="size-6 text-green-800" />}
          iconBackground="bg-green-100/50"
          heading="Active Automations"
          data={dashboardData.active_automations}
        />
      </div>
      <div className="border border-gray-200 rounded-lg p-2 w-full">
        <div className="flex justify-between items-center flex-wrap">
          <h2 className="ml-2 mt-2 text-base lg:text-lg">
            Tokens used by billing period month
          </h2>
          <div className="flex flex-col mx-2">
            <label className="text-2xs uppercase text-gray-500 tracking-wide font-semibold">
              Timezone
            </label>
            <Button
              paddingZero
              variant="transparent-primary"
              onClick={() => navigate(ROUTE_PROFILE)}
            >
              {dashboardData?.timezone}
            </Button>
          </div>
        </div>
        <BarChart data={dashboardData?.token_usage} />
        <div className="mb-4 text-gray-500 text-sm flex items-center gap-1 justify-center">
          <div className="rounded-sm w-3 h-3 bg-[#31C471]" />
          {`${formatNumberWithCommas(dashboardData?.tokens_used || 0)} token${dashboardData?.tokens_used > 1 ? "s" : ""} used`}
        </div>
      </div>
      {articlesData?.data?.length > 0 && (
        <div className="w-full mt-4 flex flex-col gap-2">
          <PageHeading
            heading="Recent Articles"
            tooltip="Refresh"
            loading={isFetchingArticles}
            refetch={refetchArticles}
          />
          <div>
            <ArticleTableContainer articles={articlesData.data} />
            <div className="flex justify-end mt-2">
              <Button
                variant="transparent-primary"
                className="flex items-center gap-1"
                onClick={() => navigate(ROUTE_ARTICLES)}
              >
                See all articles <ChevronRightIcon className="w-3.5 h-3.5" />
              </Button>
            </div>
          </div>
        </div>
      )}
    </SpringContainer>
  );
};

export default React.memo(Dashboard);
