import cn from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

interface TruncatedTextProps {
  text: string;
  id: string;
  onClick?: () => void;
  className?: string;
}

const TruncatedText = ({
  text,
  id,
  onClick,
  className,
}: TruncatedTextProps) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isTextTruncated, setIsTextTruncated] = useState(false);

  useEffect(() => {
    const checkTruncation = () => {
      if (textRef.current) {
        const isTruncated =
          textRef.current.scrollWidth > textRef.current.clientWidth;
        setIsTextTruncated(isTruncated);
      }
    };

    checkTruncation();
    window.addEventListener("resize", checkTruncation);
    return () => window.removeEventListener("resize", checkTruncation);
  }, [text]);

  const buttonProps = onClick
    ? {
        role: "button",
        tabIndex: 0,
        onClick,
        className: cn("truncate overflow-hidden cursor-pointer", className),
      }
    : {
        className: cn("truncate overflow-hidden", className),
      };

  return (
    <>
      <div
        ref={textRef}
        {...buttonProps}
        {...(isTextTruncated && {
          "data-tooltip-id": id,
          "data-tooltip-content": text,
        })}
      >
        {text}
      </div>
      {isTextTruncated && (
        <ReactTooltip
          id={id}
          place="bottom-start"
          className="max-w-md whitespace-normal break-all bg-white text-gray-900 border border-gray-200 shadow-lg !opacity-100"
          style={{
            padding: "8px 12px",
            fontSize: "14px",
            zIndex: 50,
            wordBreak: "break-word",
            hyphens: "auto",
            overflowWrap: "break-word",
          }}
          delayShow={200}
          delayHide={0}
        />
      )}
    </>
  );
};

export default TruncatedText;
