import { useFormikContext } from "formik";
import React from "react";
import InfoTooltip from "../../../../../components/common/InfoTooltip";
import { Template } from "../../../../../models";

export default function CreativityLevelSelector() {
  const { values, setFieldValue } = useFormikContext<Partial<Template>>();
  return (
    <div>
      <label
        htmlFor="temperature"
        className="mb-1 flex items-center gap-2 uppercase text-xs tracking-wide font-medium leading-6 text-gray-900"
      >
        Level of Creativity
        <InfoTooltip
          id="creativity"
          tooltip="The level of creativity will somewhat control the randomness of the text generated. A minimal creativity makes the output more deterministic and focused. A high creativity results in more diverse and creative output which can also be, at times, unpredictable."
        />
      </label>
      <div className="relative mb-6 w-96">
        <input
          id="labels-range-input"
          type="range"
          step="0.1"
          min="0.2"
          max="0.9"
          name="temperature"
          value={values?.temperature}
          onChange={({ target: { value } }) =>
            setFieldValue("temperature", value)
          }
          className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
        />
        <span className="text-sm text-gray-500 absolute start-0 -bottom-6">
          Minimal
        </span>
        <span className="text-sm text-gray-500 absolute start-1/3 -translate-x-1/2 rtl:translate-x-1/2 -bottom-6">
          Basic
        </span>
        <span className="text-sm text-gray-500 absolute start-2/3 -translate-x-1/2 rtl:translate-x-1/2 -bottom-6">
          Moderate
        </span>
        <span className="text-sm text-gray-500 absolute end-0 -bottom-6">
          High
        </span>
      </div>
    </div>
  );
}
