import { useFormikContext } from "formik";
import React from "react";
import SelectV2 from "../../../../components/form/CustomSelect";
import { EngineEnum } from "../../../../enums/engine";
import { Template } from "../../../../models";
import { getEngineName, getEngineSVG } from "../../../../utils";
import TemplateContainer from "../common/TemplateContainer";

export const engineOptions = [
  {
    value: EngineEnum.CLAUDE_3_OPUS_20240229,
    label: getEngineName(EngineEnum.CLAUDE_3_OPUS_20240229),
    icon: getEngineSVG(EngineEnum.CLAUDE_3_OPUS_20240229),
    description: "",
  },
  {
    value: EngineEnum.CLAUDE_3_5_SONNET_20240620,
    label: getEngineName(EngineEnum.CLAUDE_3_5_SONNET_20240620),
    icon: getEngineSVG(EngineEnum.CLAUDE_3_5_SONNET_20240620),
    description: "",
  },
  {
    value: EngineEnum.CLAUDE_3_5_SONNET_20241022,
    label: getEngineName(EngineEnum.CLAUDE_3_5_SONNET_20241022),
    icon: getEngineSVG(EngineEnum.CLAUDE_3_5_SONNET_20241022),
    description: "",
  },
  {
    value: EngineEnum.CLAUDE_3_5_HAIKU_20241022,
    label: getEngineName(EngineEnum.CLAUDE_3_5_HAIKU_20241022),
    icon: getEngineSVG(EngineEnum.CLAUDE_3_5_HAIKU_20241022),
    description: "",
  },
  {
    value: EngineEnum.CLAUDE_3_7_SONNET_20250219,
    label: getEngineName(EngineEnum.CLAUDE_3_7_SONNET_20250219),
    icon: getEngineSVG(EngineEnum.CLAUDE_3_7_SONNET_20250219),
    description: "",
  },
  {
    value: EngineEnum.GPT_4_TURBO_2024_04_09,
    label: getEngineName(EngineEnum.GPT_4_TURBO_2024_04_09),
    icon: getEngineSVG(EngineEnum.GPT_4_TURBO_2024_04_09),
    description: "",
  },
  {
    value: EngineEnum.GPT_4O_MINI_2024_07_18,
    label: getEngineName(EngineEnum.GPT_4O_MINI_2024_07_18),
    icon: getEngineSVG(EngineEnum.GPT_4O_MINI_2024_07_18),
    description: "",
  },
  {
    value: EngineEnum.O3_MINI_2025_01_31,
    label: getEngineName(EngineEnum.O3_MINI_2025_01_31),
    icon: getEngineSVG(EngineEnum.O3_MINI_2025_01_31),
    description: "",
  },
  {
    value: EngineEnum.GEMINI_1_5_PRO,
    label: getEngineName(EngineEnum.GEMINI_1_5_PRO),
    icon: getEngineSVG(EngineEnum.GEMINI_1_5_PRO),
    description: "",
  },
];

const Engine = () => {
  const { values, setFieldValue } = useFormikContext<Partial<Template>>();
  return (
    <div className="animate-fadeIn">
      <TemplateContainer isSuperUserFeature>
        <SelectV2
          label="Template Engine"
          placeholder="-"
          options={engineOptions}
          selected={engineOptions.find(
            (option) => option.value === values?.engine_id
          )}
          setSelected={(option) => {
            setFieldValue("engine_id", option?.value || null);
          }}
          maxMenuHeight={150}
        />
      </TemplateContainer>
    </div>
  );
};

export default Engine;
