import React from "react";
import { Outlet } from "react-router-dom";

const PublicLayout = () => {
  return (
    <main className="container mx-auto h-full relative">
      <Outlet />
    </main>
  );
};

export default PublicLayout;
