import { useFormikContext } from "formik";
import React from "react";
import { Template } from "../../../../../models";
import TemplateContainer from "../../common/TemplateContainer";
import FaqCountSelector from "./FaqCountSelector";
import FaqFormatSelector from "./FaqFormatSelector";

const Faq = () => {
  const { values } = useFormikContext<Partial<Template>>();

  const includeFaq = Boolean(values?.include_faq);
  if (!includeFaq) return null;

  return (
    <div className="space-y-6 animate-fadeIn">
      <TemplateContainer>
        <FaqFormatSelector />
        <FaqCountSelector />
      </TemplateContainer>
    </div>
  );
};

export default Faq;
