import { useFormikContext } from "formik";
import React from "react";
import { Template } from "../../../../../models";
import SwitchContainer from "../../common/SwitchContainer";

export default function ImageExcludeTextToggle() {
  const { values, setFieldValue } = useFormikContext<Partial<Template>>();
  return (
    <SwitchContainer
      title="Attempt to exclude words and text"
      enabled={values.exclude_image_text || false}
      setEnabled={() => {
        setFieldValue("exclude_image_text", !values?.exclude_image_text);
      }}
    />
  );
}
