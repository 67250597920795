import { useFormikContext } from "formik";
import React from "react";
import { SourceEnum } from "../../../../enums/template";
import { Template } from "../../../../models";
import SwitchContainer from "../common/SwitchContainer";

export default function EmbedVideoToggle() {
  const { values, setFieldValue } = useFormikContext<Partial<Template>>();
  if (
    ![
      SourceEnum.INSTAGRAM_REELS,
      SourceEnum.YOUTUBE_SHORTS,
      SourceEnum.YOUTUBE_VIDEOS,
      SourceEnum.APPLE_PODCASTS,
    ].includes(values?.source_id!)
  ) {
    return null;
  }

  return (
    <SwitchContainer
      title="Embed the video into the Article"
      subTitle=""
      tooltip="This will embed the video right into the article."
      enabled={!!values?.include_web_embed}
      setEnabled={() =>
        setFieldValue("include_web_embed", !values?.include_web_embed)
      }
    />
  );
}
