import React from "react";
import { Navigate, Outlet, RouteProps, useLocation } from "react-router-dom";
import { ROUTE_LOGIN } from "../../constants/routes";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import Layout from "../layouts/Layout";

const ProtectedRoute: React.FC<RouteProps> = () => {
  const location = useLocation();
  const authToken = useAppSelector((state: RootState) => state.auth.token);

  return authToken ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate
      to={ROUTE_LOGIN}
      state={{ from: location }}
    />
  );
};

export default ProtectedRoute;
