import {
  ArchiveBoxIcon,
  EllipsisHorizontalIcon,
  PauseIcon,
  PlayIcon,
  Square2StackIcon,
} from "@heroicons/react/24/outline";
import Dropdown from "rc-dropdown";
import "rc-dropdown/assets/index.css";
import Menu, { Item as MenuItem } from "rc-menu";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { generatePath, useNavigate } from "react-router-dom";
import ConfirmationModal from "../../../components/features/ConfirmationModal";
import { ROUTE_TEMPLATES_EDIT } from "../../../constants/routes";
import { TemplateStatusEnum } from "../../../enums/template";
import { useAppSelector } from "../../../redux/hooks";
import {
  useCloneTemplateByIdMutation,
  useUpdateTemplateByIdMutation,
} from "../../../redux/services/templates";
import { RootState } from "../../../redux/store";
import { getCurrentDateTimeFormatted } from "../../../utils";

type TemplateActionsProps = {
  id: string;
  name: string;
  status: TemplateStatusEnum;
  isAuto: boolean;
};

enum ActionType {
  View = "view",
  Edit = "edit",
  Rerun = "rerun",
  Archive = "archive",
  Clone = "clone",
  Start = "start",
  Pause = "pause",
}

const TemplateActionsDropdown: React.FC<TemplateActionsProps> = ({
  id,
  name,
  status,
  isAuto,
}) => {
  const navigate = useNavigate();
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState<boolean>(false);
  const openModal = () => setIsArchiveModalOpen(true);
  const closeModal = () => setIsArchiveModalOpen(false);
  const handleConfirm = () => {
    handleArchive();
  };
  const [visible, setVisible] = useState<boolean>(false);

  const accountId = useAppSelector(
    (state: RootState) => state.user.selectedAccount?.account_id
  );

  const [updateTemplateById] = useUpdateTemplateByIdMutation();
  const [cloneTemplateById] = useCloneTemplateByIdMutation();

  const getMenuItems = () => {
    const iconStyle = "w-4 text-gray-500";
    const items = [
      {
        key: ActionType.Clone,
        label: "Make a copy",
        icon: <Square2StackIcon className={iconStyle} />,
      },
      {
        key: ActionType.Archive,
        label: "Archive",
        icon: <ArchiveBoxIcon className={iconStyle} />,
      },
    ];

    if (isAuto) {
      const canStart =
        status === TemplateStatusEnum.PAUSED ||
        status === TemplateStatusEnum.DRAFT;
      const actionType = canStart ? ActionType.Start : ActionType.Pause;
      const label = canStart ? "Start" : "Pause";
      const icon = canStart ? (
        <PlayIcon className={iconStyle} />
      ) : (
        <PauseIcon className={iconStyle} />
      );

      items.unshift({
        key: actionType,
        label,
        icon,
      });
    }

    return items;
  };

  const handleArchive = async () => {
    if (!accountId) return;

    try {
      await updateTemplateById({
        template_id: id,
        account_id: accountId,
        archived_at: getCurrentDateTimeFormatted(),
        template_status_id: TemplateStatusEnum.ARCHIVED,
      }).unwrap();

      closeModal();
      toast.success("Successfully archived.");
    } catch (error) {
      console.error("Archive failed", error);
      toast.error("Failed to archive. Please try again.");
    }
  };

  const cloneTemplate = async () => {
    if (!accountId) return;

    try {
      await cloneTemplateById({
        template_id: id,
        account_id: accountId,
      }).unwrap();
      toast.success("Successfully created a template copy.");
    } catch (error) {
      console.error("Clone failed", error);
      toast.error("Failed to make a copy. Please try again.");
    }
  };

  const handleStatusChange = async (templateStatusId: TemplateStatusEnum) => {
    if (!accountId) return;

    try {
      await updateTemplateById({
        template_id: id,
        account_id: accountId,
        template_status_id: templateStatusId,
      }).unwrap();

      closeModal();
      const statusText =
        templateStatusId === TemplateStatusEnum.PAUSED ? "paused" : "started";
      toast.success(`Successfully ${statusText} the template.`);
    } catch (error) {
      const statusText =
        templateStatusId === TemplateStatusEnum.PAUSED ? "pause" : "start";
      toast.error(`Failed to ${statusText}. Please try again.`);
    }
  };

  const handleAction = (action: string) => {
    const actionType = action as ActionType;
    switch (actionType) {
      case ActionType.Archive:
        openModal();
        break;
      case ActionType.Clone:
        cloneTemplate();
        break;
      case ActionType.Start:
        handleStatusChange(TemplateStatusEnum.PENDING);
        break;
      case ActionType.Pause:
        handleStatusChange(TemplateStatusEnum.PAUSED);
        break;
      default:
        break;
    }
    setVisible(false);
  };

  const menu = (
    <Menu
      onClick={(e) => {
        e.domEvent.stopPropagation();
        handleAction(e.key);
      }}
      className="text-sm"
      selectable={false}
      style={{ minWidth: 180 }}
    >
      {getMenuItems().map((option) => (
        <MenuItem
          key={option.key}
          className="hover:bg-gray-100 hover:text-primary flex gap-1 items-center justify-between"
          style={{ padding: "10px", cursor: "pointer" }}
        >
          <div className="flex gap-2 items-center">
            {option.icon}
            {option.label}
          </div>
        </MenuItem>
      ))}
    </Menu>
  );

  const getButtonText = (status: TemplateStatusEnum) => {
    let buttonText = "";
    switch (status) {
      case TemplateStatusEnum.DRAFT:
        buttonText = "Edit";
        break;
      case TemplateStatusEnum.PENDING:
      case TemplateStatusEnum.QUEUED:
      case TemplateStatusEnum.AUTO:
      case TemplateStatusEnum.PAUSED:
      case TemplateStatusEnum.STOPPED:
        buttonText = "View";
        break;
      case TemplateStatusEnum.COMPLETED:
        buttonText = "Rerun";
        break;
      default:
        buttonText = "View";
    }
    return buttonText;
  };

  return (
    <>
      <ConfirmationModal
        isOpen={isArchiveModalOpen}
        onRequestClose={closeModal}
        onConfirm={handleConfirm}
        title="Confirm Archive"
        message={
          <>
            Are you sure you want to archive <strong>"{name}"</strong>?
          </>
        }
        confirmText="Archive"
        width={500}
      />
      <div className="flex items-center justify-end">
        <div className="flex items-center gap-1">
          <button
            className="text-primary/90 hover:text-primary text-xs uppercase text-left p-1 hover:bg-primary/10 rounded"
            onClick={(e) => {
              e.stopPropagation();
              navigate(generatePath(ROUTE_TEMPLATES_EDIT, { id }));
            }}
          >
            {getButtonText(status)}
          </button>
          <div className="w-px h-4 bg-gray-200" />
        </div>
        <div className="flex gap-2 items-center justify-end">
          <Dropdown
            overlay={menu}
            trigger={["click"]}
            visible={visible}
            onVisibleChange={(newVisible: boolean) => setVisible(newVisible)}
          >
            <button
              className="ml-1 flex gap-2 items-center justify-center w-full hover:bg-primary/10 rounded"
              onClick={(e: any) => {
                e.stopPropagation();
              }}
            >
              <EllipsisHorizontalIcon className="size-5 text-primary" />
            </button>
          </Dropdown>
        </div>
      </div>
    </>
  );
};

export default TemplateActionsDropdown;
