import { useFormikContext } from "formik";
import React from "react";
import NumberTextArea from "../../../../../components/form/NumberTextArea";
import { SourceEnum } from "../../../../../enums/template";
import { Template } from "../../../../../models";
import {
  getMediaLinkPlaceholder,
  getMediaLinkTooltip,
} from "../../../../../utils";
import AutomationScheduleSettings from "./AutomationScheduleSettings";
import AutomationToggle from "./AutomationToggle";
import RssFeedEntrySelector from "./RssFeedEntrySelector";

export default function RssFeedUrlsInput() {
  const { values, errors, setFieldValue } =
    useFormikContext<Partial<Template>>();
  return (
    <div className="space-y-4">
      <NumberTextArea
        label="RSS Feed URLS"
        tooltip={getMediaLinkTooltip(SourceEnum.RSS_FEEDS)}
        placeholder={getMediaLinkPlaceholder(SourceEnum.RSS_FEEDS)}
        value={values?.rss_feed_urls ?? ""}
        errorMessage={errors?.rss_feed_urls ?? ""}
        handleChange={(rssFeedUrls) => {
          setFieldValue("rss_feed_urls", rssFeedUrls);
        }}
      />
      <RssFeedEntrySelector />
      <AutomationToggle />
      {values?.is_auto_enabled && <AutomationScheduleSettings />}
    </div>
  );
}
