import { ImageEngineEnum } from "../../../enums/imageEngine";
import { PointOfViewEnum, SourceEnum } from "../../../enums/template";
import { Template } from "../../../models";

const resetMediaField = (payload: Partial<Template>) => {
  payload.media_url = null;
};

const resetAutoScheduleFields = (payload: Partial<Template>) => {
  payload.is_auto_enabled = false;
  payload.auto_days = [];
  payload.auto_time1 = null;
  payload.auto_time2 = null;
};

const resetAutoUsernameFields = (payload: Partial<Template>) => {
  payload.username = null;
  payload.external_user_id = null;
};

const resetRssFeedUrlsField = (payload: Partial<Template>) => {
  payload.rss_feed_urls = null;
  payload.rss_get_random_entry = null;
};

const resetTextBlockField = (payload: Partial<Template>) => {
  payload.text_block = null;
};

const resetImageFields = (payload: Partial<Template>) => {
  payload.image_size_id = null;
  payload.image_style_id = null;
  payload.image_tone_id = null;
};

const resetWebEmbedField = (payload: Partial<Template>) => {
  payload.include_web_embed = false;
};

const resetFaqFields = (payload: Partial<Template>) => {
  payload.faq_question_prefix_id = null;
  payload.faq_count = null;
};

const resetSpeakerField = (payload: Partial<Template>) => {
  payload.is_speaker_the_author = null;
};

const resetRecraftImageExcludes = (payload: Partial<Template>) => {
  payload.exclude_image_text = false;
  payload.exclude_image_faces = false;
};

const handleDestinationChange = (
  payload: Partial<Template>,
  destination_id_list: string[]
) => {
  payload.old_destination_id = destination_id_list[0];
  payload.destination_id = payload.destination_id ?? null;
};

const commaSeparateUrls = (payload: Partial<Template>) => {
  if (payload.rss_feed_urls) {
    payload.rss_feed_urls = payload.rss_feed_urls.split("\n").join(", ");
  }
};

const removeURLQueryParamsExceptForV = (url: string) => {
  const queryStartIndex = url.indexOf("?");
  if (queryStartIndex === -1) {
    // No query params, return the original URL
    return url;
  }
  const baseUrl = url.substring(0, queryStartIndex);
  const queryParams = url.substring(queryStartIndex + 1).split("&");

  // Find the 'v' parameter
  const vParam: string | undefined = queryParams.find((param) =>
    param.toLowerCase().startsWith("v=")
  );
  return vParam ? `${baseUrl}?${vParam}` : baseUrl;
};

export const cleanMediaUrl = (
  url: string | null | undefined,
  sourceId: SourceEnum | undefined
) => {
  if (!url) return null;

  if (sourceId === SourceEnum.INSTAGRAM_REELS) {
    let cleanUrl = url;
    if (cleanUrl.includes("?")) {
      cleanUrl = cleanUrl.substring(0, url.indexOf("?"));
    }
    if (cleanUrl.includes("reels")) {
      cleanUrl = cleanUrl.replace("/reels/", "/reel/");
    }
    return cleanUrl;
  }

  if (sourceId === SourceEnum.YOUTUBE_VIDEOS && url.includes("?")) {
    return removeURLQueryParamsExceptForV(url);
  }

  return url;
};

export const generateRequestPayload = (values: Partial<Template>) => {
  const {
    template_id,
    upload_media_url,
    destination_id_list,
    old_destination_id,
    ...rest
  } = values;

  let payload: Partial<Template> = {
    ...rest,
  };

  if (template_id) payload.template_id = template_id;

  /* ============================
   * Handle Auto and Manual Mode
   * ============================ */
  if (!payload.is_auto_enabled) {
    resetAutoScheduleFields(payload);
    resetAutoUsernameFields(payload);
    payload.media_url = cleanMediaUrl(payload.media_url, payload.source_id);
  } else {
    resetMediaField(payload);
    payload.external_user_id =
      payload.source_id !== SourceEnum.INSTAGRAM_REELS
        ? values.external_user_id
        : null;
  }

  /* ============================
   * Handle Source-Specific Settings
   * ============================ */
  switch (payload.source_id) {
    case SourceEnum.INSTAGRAM_REELS:
    case SourceEnum.YOUTUBE_SHORTS:
    case SourceEnum.YOUTUBE_VIDEOS:
    case SourceEnum.APPLE_PODCASTS:
      resetTextBlockField(payload);
      resetRssFeedUrlsField(payload);
      break;
    case SourceEnum.RSS_FEEDS:
      resetAutoUsernameFields(payload);
      resetMediaField(payload);
      resetWebEmbedField(payload);
      resetTextBlockField(payload);
      commaSeparateUrls(payload);
      break;
    case SourceEnum.TEXT_BLOCK:
      resetAutoScheduleFields(payload);
      resetAutoUsernameFields(payload);
      resetMediaField(payload);
      resetRssFeedUrlsField(payload);
      resetWebEmbedField(payload);
      break;
    case SourceEnum.UPLOAD_MEDIA:
      payload.media_url = upload_media_url;
      resetAutoScheduleFields(payload);
      resetAutoUsernameFields(payload);
      resetWebEmbedField(payload);
      resetRssFeedUrlsField(payload);
      resetTextBlockField(payload);
      break;
  }

  if (!payload.include_image) resetImageFields(payload);
  if (!payload.include_faq) resetFaqFields(payload);
  if (payload.point_of_view_id === PointOfViewEnum.THIRD_PERSON) {
    resetSpeakerField(payload);
  }

  /* ============================
   * Handle Destination Changes
   * ============================ */
  const hasDestinationChanged =
    Array.isArray(destination_id_list) &&
    destination_id_list?.[0] !== payload.destination_id;

  if (hasDestinationChanged) {
    handleDestinationChange(payload, destination_id_list);
  }

  /* ============================
   * Handle Image Engine Fields
   * ============================ */
  if (values?.image_engine_id === ImageEngineEnum.DALL_E_3) {
    resetRecraftImageExcludes(payload);
  }

  return payload;
};
