import { SourceEnum } from "../../../enums/template";
import {
  getInvalidMediaLinkErrorMessage,
  isValidMediaLink,
} from "../../../utils";

export const validateRSSFeeds = (
  rssFeedUrls: string | null
): string | undefined => {
  if (!rssFeedUrls?.trim()) return "RSS FEED URL IS REQUIRED";

  for (const mediaUrl of rssFeedUrls.split("\n").map((url) => url.trim())) {
    if (!mediaUrl) continue;
    try {
      new URL(mediaUrl);
    } catch {
      return `INVALID URL: ${mediaUrl}`;
    }
  }
  return undefined;
};

export const validateCustomText = (
  transcript: string | null
): string | undefined => {
  if (!transcript?.trim()) return "Transcript is required.";

  if (transcript?.trim()?.length < 200)
    return "The provided transcript is too short (must be at least 200 characters). Please add more details or context.";
  return undefined;
};

export const validateMediaLink = (
  mediaUrl: string | null,
  sourceId: SourceEnum
): string | undefined => {
  if (!mediaUrl?.trim()) return "Media link is required";

  if (!isValidMediaLink(mediaUrl, sourceId)) {
    return getInvalidMediaLinkErrorMessage(sourceId);
  }
  return undefined;
};

export const validateRequiredField = (
  value: string | null,
  fieldName: string
): string | undefined => {
  return value?.trim() ? undefined : `${fieldName} is required`;
};
