import { useFormikContext } from "formik";
import React from "react";
import RadioGroup, { Option } from "../../../../../components/form/RadioGroup";
import { RSSFeedEntrySelection } from "../../../../../enums/template";
import { Template } from "../../../../../models";

const rssFeedEntryOptions: Option[] = [
  {
    id: RSSFeedEntrySelection.RANDOM,
    name: "Get Random Entry",
  },
  {
    id: RSSFeedEntrySelection.LATEST,
    name: "Get Latest Entry",
  },
];

export default function RssFeedEntrySelector() {
  const { values, setFieldValue } = useFormikContext<Partial<Template>>();
  return (
    <RadioGroup
      options={rssFeedEntryOptions}
      name="rss_get_random_entry"
      selected={
        values?.rss_get_random_entry
          ? RSSFeedEntrySelection.RANDOM
          : RSSFeedEntrySelection.LATEST
      }
      onClick={({ id }: Option) =>
        setFieldValue(
          "rss_get_random_entry",
          id === RSSFeedEntrySelection.RANDOM
        )
      }
    />
  );
}
