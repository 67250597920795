import { ArrowPathIcon } from "@heroicons/react/24/outline";
import React from "react";
import { Tooltip } from "react-tooltip";
import Button from "../form/Button";
import LoadingSpinner from "../loaders/LoadingSpinner";

const RefreshButton = ({
  tooltip,
  refetch,
  className,
  loading = false,
}: {
  tooltip?: string;
  refetch?: Function;
  className?: string;
  loading?: boolean;
}) => {
  return (
    <>
      {tooltip && (
        <Tooltip
          id="btn"
          place="bottom"
          delayShow={500}
        >
          {tooltip}
        </Tooltip>
      )}
      <Button
        data-tooltip-id="btn"
        data-tooltip-content={tooltip}
        type="button"
        variant="icon"
        loading={loading}
        onClick={(e: any) => {
          e.stopPropagation();
          refetch?.();
        }}
        aria-label={tooltip}
        className={className}
      >
        {loading ? (
          <LoadingSpinner size="20" />
        ) : (
          <ArrowPathIcon className="size-5" />
        )}
      </Button>
    </>
  );
};

export default RefreshButton;
