import { useFormikContext } from "formik";
import React from "react";
import { Template } from "../../../../../models";
import SwitchContainer from "../../common/SwitchContainer";

export default function IncludeCreditsToggle() {
  const { values, setFieldValue } = useFormikContext<Partial<Template>>();
  return (
    <SwitchContainer
      title="Include Credits"
      subTitle=""
      tooltip='This will add "Article by", "Original author" and "Source Link" to the bottom of the article.'
      enabled={!!values?.include_credits}
      setEnabled={() =>
        setFieldValue("include_credits", !values?.include_credits)
      }
    />
  );
}
