import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import InfoTooltip from "../../../../components/common/InfoTooltip";
import Input from "../../../../components/form/Input";
import RadioGroup, { Option } from "../../../../components/form/RadioGroup";
import TextArea from "../../../../components/form/TextArea";
import { PointOfViewEnum } from "../../../../enums/template";
import { Template } from "../../../../models";
import SwitchContainer from "../common/SwitchContainer";

export const pointOfViewOptions = [
  {
    id: PointOfViewEnum.THIRD_PERSON,
    name: "Third-person",
  },
  {
    id: PointOfViewEnum.FIRST_PERSON,
    name: "First-person",
  },
];

const PointOfView = () => {
  const { values, setFieldValue } = useFormikContext<Partial<Template>>();
  const [initialized, setInitialized] = useState(false);
  const [enableCtxFields, setEnableCtxFields] = useState(false);

  useEffect(() => {
    if (
      !initialized &&
      !enableCtxFields &&
      (values?.is_speaker_the_author ||
        values?.speaker_name ||
        values?.speaker_about)
    ) {
      setEnableCtxFields(true);
      setInitialized(true);
    }
  }, [
    initialized,
    enableCtxFields,
    values?.is_speaker_the_author,
    values?.speaker_name,
    values?.speaker_about,
  ]);

  const FIRST_PERSON_POV =
    values?.point_of_view_id === PointOfViewEnum.FIRST_PERSON;

  return (
    <div className="w-full">
      <h3 className="mb-2 tracking-wide font-medium leading-6 text-gray-900 text-xs flex items-center gap-2 uppercase">
        Point of view
        <InfoTooltip id="point_of_view">
          <div className="flex flex-col gap-4">
            <div>
              First-person:
              <div className="ml-6">
                <div>
                  A more opinionated point of view — conveys the perspective and
                  thoughts of the speaker or writer.
                </div>
                <div>Pronouns: I, me, my, mine, we, our, us.</div>
              </div>
            </div>
            <div>
              Third-person:
              <div className="ml-6">
                <div>
                  A more professional point of view — conveys a neutral and
                  objective presentation of the source material.
                </div>
                <div>
                  Pronouns: he, she, it, him, her, his, hers, they, them, their,
                  theirs.
                </div>
              </div>
            </div>
          </div>
        </InfoTooltip>
      </h3>
      <RadioGroup
        options={pointOfViewOptions}
        name="point_of_view_id"
        selected={values?.point_of_view_id}
        onClick={({ id }: Option) => setFieldValue("point_of_view_id", id)}
      />
      {!enableCtxFields && (
        <button
          type="button"
          className="mt-6 uppercase text-2xs text-primary disabled:text-gray-500 disabled:cursor-not-allowed font-semibold"
          onClick={() => setEnableCtxFields(true)}
        >
          Add Speaker Context
        </button>
      )}

      {enableCtxFields && (
        <div className="mt-6 space-y-4 w-full">
          {FIRST_PERSON_POV && (
            <SwitchContainer
              title="I am the speaker in the source"
              enabled={values?.is_speaker_the_author || false}
              setEnabled={() =>
                setFieldValue(
                  "is_speaker_the_author",
                  !values?.is_speaker_the_author
                )
              }
              className="mt-6"
            />
          )}
          <Input
            label="Speaker Name"
            name="speaker_name"
            value={values?.speaker_name ?? ""}
            placeholder="Jane Doe"
            maxLength={200}
            onChange={({ target: { value } }) =>
              setFieldValue("speaker_name", value)
            }
            onBlur={({ target: { value } }) => {
              setFieldValue("speaker_name", value.trim());
            }}
          />
          <TextArea
            label="About the speaker"
            name="speaker_about"
            value={values?.speaker_about ?? ""}
            placeholder="example: Jane Doe is a fitness coach."
            maxLength={500}
            maxHeight={150}
            showCharacterCount={true}
            onChange={({ target: { value } }) =>
              setFieldValue("speaker_about", value)
            }
          />
        </div>
      )}
    </div>
  );
};

export default PointOfView;
