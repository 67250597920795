import React from "react";
import { useNavigate } from "react-router-dom";
import SidebarSVG from "../../assets/svg/sidebar.svg";
import { ROUTE_ROOT } from "../../constants/routes";
import { Logo } from "../common/Logo";
import UserMenu from "../features/UserMenu";
import Button from "../form/Button";

const Header = ({
  sidebarOpen,
  setSidebarOpen,
  onLogoClick,
}: {
  sidebarOpen?: boolean;
  setSidebarOpen?: (open: boolean) => void;
  onLogoClick?: () => void;
}) => {
  const navigate = useNavigate();
  return (
    <header className="relative w-full h-14 border-b border-gray-200 flex items-center">
      <div className="flex items-center h-14 px-4">
        {setSidebarOpen && (
          <Button
            variant="icon"
            onClick={() => setSidebarOpen(!sidebarOpen)}
          >
            <img
              src={SidebarSVG}
              alt="sidebar-icon"
              className="h-6 w-6"
            />
          </Button>
        )}
        <Button
          variant="icon"
          onClick={() => {
            if (onLogoClick) {
              onLogoClick();
            } else {
              navigate(ROUTE_ROOT);
            }
          }}
        >
          <Logo width={130} />
        </Button>
      </div>
      <div className="ml-auto">
        <UserMenu />
      </div>
    </header>
  );
};

export default Header;
