import { useFormikContext } from "formik";
import React from "react";
import InfoTooltip from "../../../../../components/common/InfoTooltip";
import RadioGroup, { Option } from "../../../../../components/form/RadioGroup";
import { ImageEngineEnum } from "../../../../../enums/imageEngine";
import { ImageSizeEnum } from "../../../../../enums/template";
import { Template } from "../../../../../models";

const dallE3ImageSizeOptions = [
  { id: ImageSizeEnum.LANDSCAPE, name: "LANDSCAPE", description: "1792x1024" },
  { id: ImageSizeEnum.PORTRAIT, name: "PORTRAIT", description: "1024x1792" },
  { id: ImageSizeEnum.SQUARE, name: "SQUARE", description: "1024x1024" },
];

const recraftImageSizeOptions = [
  { id: ImageSizeEnum.LANDSCAPE, name: "LANDSCAPE", description: "1820x1024" },
  { id: ImageSizeEnum.PORTRAIT, name: "PORTRAIT", description: "1024x1820" },
  { id: ImageSizeEnum.SQUARE, name: "SQUARE", description: "1024x1024" },
];

export default function ImageSizeSelector() {
  const { values, setFieldValue } = useFormikContext<Partial<Template>>();
  return (
    <div className="w-max">
      <h3 className="mb-2 tracking-wide font-medium leading-6 text-gray-900 text-xs flex items-center gap-2 uppercase">
        Image size
        <InfoTooltip
          id="image_size"
          tooltip="This is your preferred image size. Landscape is wide, portrait is tall, and then there is square."
        />
      </h3>
      <RadioGroup
        options={
          values?.image_engine_id === ImageEngineEnum.RECRAFT_V3
            ? recraftImageSizeOptions
            : dallE3ImageSizeOptions
        }
        name="image_size_id"
        selected={values?.image_size_id}
        onClick={({ id }: Option) => setFieldValue("image_size_id", id)}
      />
    </div>
  );
}
