import { EngineEnum } from "../enums/engine";
import { ImageEngineEnum } from "../enums/imageEngine";
import {
  ArticleTypeEnum,
  BrandPersonaEnum,
  BrandToneEnum,
  BrandVoiceEnum,
  FaqQuestionPrefixEnum,
  ImageSizeEnum,
  ImageStyleEnum,
  ImageToneEnum,
  PointOfViewEnum,
  SourceEnum,
  TemplateStatusEnum,
  TranscribeSubjectEnum,
} from "../enums/template";

export class Template {
  // Backend only
  include_intro: boolean;
  media_author_name: string;

  template_id: string | null;
  // Template name
  name: string | null;

  // Platform
  source_id: SourceEnum;

  // Template status
  template_status_id!: TemplateStatusEnum;

  // Media link
  media_url: string | null;
  upload_media_url: string | null; // frontend-only
  rss_feed_urls: string | null;
  text_block: string | null;

  // Auto
  is_auto_enabled: boolean;
  username: string | null;
  external_user_id: string | null; // Instagram only
  auto_days: number[] | null;
  auto_time1: string | null;
  auto_time2: string | null;
  profile_url: string | null;
  timezone: string;

  // RSS Feed Selection
  rss_get_random_entry: boolean | null;

  // Include image
  include_image: boolean;
  image_size_id: ImageSizeEnum | null;
  image_style_id: ImageStyleEnum | null;
  image_tone_id: ImageToneEnum | null;

  // Include web embed
  include_web_embed: boolean;

  // Include FAQs
  include_faq: boolean;
  faq_count: number | null;
  faq_question_prefix_id: FaqQuestionPrefixEnum | null;

  // Article type
  article_type_id: number;

  // Point of view
  point_of_view_id: PointOfViewEnum | null;
  is_speaker_the_author: boolean | null;
  speaker_name: string | null;
  speaker_about: string | null;

  // Brand settings
  brand_persona_id: BrandPersonaEnum | null;
  brand_voice_id: BrandVoiceEnum | null;
  brand_tone_id: BrandToneEnum | null;

  // Include credits
  include_credits: boolean;
  bio: string;

  // Additional context
  additional_context: string | null;

  // Primary subject of content
  transcribe_subject_id: TranscribeSubjectEnum;

  // Creativity
  temperature: number;

  // Destination
  destination_id: string | null;
  old_destination_id: string | null;
  destination_id_list: string | string[] | null;

  // AI model to use
  engine_id: EngineEnum | null;
  image_engine_id: ImageEngineEnum | null;

  // Image engine negative prompts
  exclude_image_text: boolean | null;
  exclude_image_faces: boolean | null;

  constructor({
    include_intro,
    media_author_name,
    template_id,
    name,
    source_id,
    template_status_id,
    media_url,
    upload_media_url,
    rss_feed_urls,
    text_block,
    is_auto_enabled,
    username,
    external_user_id,
    auto_days,
    auto_time1,
    auto_time2,
    profile_url,
    timezone,
    rss_get_random_entry,
    include_image,
    image_size_id,
    image_style_id,
    image_tone_id,
    include_web_embed,
    include_faq,
    faq_count,
    faq_question_prefix_id,
    article_type_id,
    point_of_view_id,
    is_speaker_the_author,
    speaker_name,
    speaker_about,
    brand_persona_id,
    brand_voice_id,
    brand_tone_id,
    include_credits,
    bio,
    additional_context,
    transcribe_subject_id,
    temperature,
    destination_id,
    old_destination_id,
    destination_id_list,
    engine_id,
    image_engine_id,
    exclude_image_text,
    exclude_image_faces,
  }: any) {
    this.include_intro = include_intro || false;
    this.media_author_name = media_author_name || null;
    this.template_id = template_id || null;
    this.name = name || null;
    this.source_id = source_id || SourceEnum.INSTAGRAM_REELS;
    this.template_status_id = template_status_id || TemplateStatusEnum.PENDING;
    this.media_url = media_url || null;
    this.upload_media_url = upload_media_url || null; // frontend-only
    this.rss_feed_urls = rss_feed_urls || null;
    this.text_block = text_block || null;
    this.is_auto_enabled = is_auto_enabled || false;
    this.username = username || null;
    this.external_user_id = external_user_id || null; // Instagram-only
    this.auto_days = auto_days || [];
    this.auto_time1 = auto_time1 || null;
    this.auto_time2 = auto_time2 || null;
    this.profile_url = profile_url || null;
    this.timezone = timezone || null;
    this.rss_get_random_entry = rss_get_random_entry || false;
    this.include_image = include_image || false;
    this.image_size_id = image_size_id || null;
    this.image_style_id = image_style_id || null;
    this.image_tone_id = image_tone_id || null;
    this.include_web_embed = include_web_embed || false;
    this.include_faq = include_faq || false;
    this.faq_count = faq_count || null;
    this.faq_question_prefix_id = faq_question_prefix_id || null;
    this.article_type_id = article_type_id || ArticleTypeEnum.STANDARD_ARTICLE;
    this.point_of_view_id = point_of_view_id || PointOfViewEnum.THIRD_PERSON;
    this.is_speaker_the_author = is_speaker_the_author || false;
    this.speaker_name = speaker_name || null;
    this.speaker_about = speaker_about || null;
    this.brand_persona_id = brand_persona_id || null;
    this.brand_voice_id = brand_voice_id || null;
    this.brand_tone_id = brand_tone_id || null;
    this.include_credits = include_credits || false;
    this.bio = bio || null;
    this.additional_context = additional_context || null;
    this.transcribe_subject_id =
      transcribe_subject_id || TranscribeSubjectEnum.GENERAL;
    this.temperature = temperature || 0.5;
    this.destination_id = destination_id || null;
    this.old_destination_id = old_destination_id || null;
    this.destination_id_list = destination_id_list || null;
    this.engine_id = engine_id || null;
    this.image_engine_id = image_engine_id || null;
    this.exclude_image_text = exclude_image_text || null;
    this.exclude_image_faces = exclude_image_faces || null;
  }
}

export class TemplateDetails extends Template {
  run_timestamp: string | null;
  completed_at: string | null;
  archived_at: string | null;
  created_by: string | null;
  updated_by: string | null;
  created_at: string | null;
  article_count: number;

  constructor({
    run_timestamp,
    completed_at,
    archived_at,
    created_by,
    updated_by,
    created_at,
    article_count,
    params,
  }: any) {
    super(params);
    this.run_timestamp = run_timestamp;
    this.completed_at = completed_at;
    this.archived_at = archived_at;
    this.created_by = created_by;
    this.updated_by = updated_by;
    this.created_at = created_at;
    this.article_count = article_count;
  }
}
