import { useFormikContext } from "formik";
import React from "react";
import { Template } from "../../../../../models";
import { getCurrentTime } from "../../../../../utils";
import SwitchContainer from "../../common/SwitchContainer";

export default function AutomationToggle() {
  const { values, setFieldValue } = useFormikContext<Partial<Template>>();
  return (
    <SwitchContainer
      title="Enable Automation"
      subTitle="Auto generate articles from your profile."
      enabled={Boolean(values?.is_auto_enabled)}
      setEnabled={() => {
        setFieldValue("is_auto_enabled", !values?.is_auto_enabled);
        if (!values?.is_auto_enabled) {
          setFieldValue("auto_days", [1, 2, 3, 4, 5]);
          setFieldValue("auto_time1", getCurrentTime());
        } else {
          setFieldValue("auto_days", []);
          setFieldValue("auto_time1", null);
          setFieldValue("auto_time2", null);
        }
      }}
    />
  );
}
