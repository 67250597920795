import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageHeading from "../../components/common/PageHeading";
import { Pagination } from "../../components/common/Table";
import SpringContainer from "../../components/containers/SpringContainer";
import Button from "../../components/form/Button";
import Input from "../../components/form/Input";
import { ROUTE_DESTINATIONS_CREATE } from "../../constants/routes";
import useSelectedAccount from "../../hooks/useSelectedAccount";
import { Destination } from "../../models/destination";
import { useGetDestinationsQuery } from "../../redux/services/destinations";
import DestinationCard from "./components/DestinationCard";
import DestinationTable from "./components/DestinationTable";
import WordpressCard from "./components/WordpressCard";

export const limit = 20;
const Destinations = () => {
  const navigate = useNavigate();
  const { accountId } = useSelectedAccount();

  const [searchTerm, setSearchTerm] = useState("");
  const [submittedSearch, setSubmittedSearch] = useState("");
  const [totalDestinations, setTotalDestinations] = useState(0);
  const [searchTotalDestinations, setSearchTotalDestinations] = useState(0);
  const [offset, setOffset] = useState(0);

  const { data, isLoading, refetch, isFetching, isUninitialized } =
    useGetDestinationsQuery(
      {
        account_id: accountId || "",
        offset: offset,
        limit: limit,
        search: submittedSearch || "",
        include_total_count: true,
      },
      { skip: !accountId }
    );

  useEffect(() => {
    if (accountId) {
      refetch();
    }
  }, [accountId, offset, refetch]);

  useEffect(() => {
    if (!totalDestinations && data?.total_count !== undefined) {
      setTotalDestinations(data.total_count);
    }
  }, [data?.total_count, totalDestinations, setTotalDestinations]);

  useEffect(() => {
    if (submittedSearch && data?.total_count !== undefined) {
      setSearchTotalDestinations(data.total_count);
    } else {
      setSearchTotalDestinations(0);
    }
  }, [data?.total_count, submittedSearch, setSearchTotalDestinations]);

  const destinations = data?.data || [];
  const destinationsExists = Boolean(destinations?.length > 0);

  const renderDestinationData = () => {
    if (destinationsExists) {
      return (
        <div>
          <div className="block md:hidden max-h-[72vh] overflow-y-scroll no-scrollbar rounded-lg space-y-4">
            {destinations.map((destination: Destination) => {
              return (
                <DestinationCard
                  key={destination.destination_id}
                  destination={destination}
                />
              );
            })}
          </div>
          <div className="hidden md:block">
            <DestinationTable destinations={destinations} />
          </div>
          {(submittedSearch
            ? searchTotalDestinations > limit
            : totalDestinations > limit) && (
            <Pagination
              offset={offset}
              limit={limit}
              total={
                submittedSearch ? searchTotalDestinations : totalDestinations
              }
              setOffset={setOffset}
              style={{ marginRight: "0px" }}
            />
          )}
        </div>
      );
    } else if (submittedSearch) {
      return <div className="text-sm text-gray-700">No Destination found</div>;
    } else {
      return <WordpressCard />;
    }
  };

  if (isUninitialized || isLoading) {
    return null;
  }

  return (
    <SpringContainer className="space-y-6">
      {/* Header */}
      <div className="flex gap-4 flex-col md:flex-row md:items-center">
        <PageHeading heading="Destinations" />
        {(destinationsExists || submittedSearch) && (
          <div className="flex gap-2 md:justify-between w-full">
            <Input
              search
              value={searchTerm}
              placeholder="Search by title"
              loading={isFetching}
              className="inline-block"
              width={350}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === "Tab") {
                  setSubmittedSearch(searchTerm);
                }
              }}
              onBlur={() => setSubmittedSearch(searchTerm)}
            />
            <Button
              variant="primary"
              className="flex gap-1 items-center justify-center ml-auto"
              onClick={() => {
                navigate(ROUTE_DESTINATIONS_CREATE);
              }}
            >
              Add
            </Button>
          </div>
        )}
      </div>

      {renderDestinationData()}
    </SpringContainer>
  );
};

export default Destinations;
