import { useFormikContext } from "formik";
import React from "react";
import UploadMedia from "../../../../../components/features/UploadMedia";
import { Template } from "../../../../../models";

export default function UploadMediaInput() {
  const { values, errors, setFieldValue, setErrors } =
    useFormikContext<Partial<Template>>();
  return (
    <UploadMedia
      url={values.upload_media_url ?? ""}
      error={errors?.upload_media_url as string}
      onUpload={(value) => {
        setFieldValue("upload_media_url", value);
        // handle resetting auto fields as UPLOAD is one-off
        if (values?.is_auto_enabled) {
          setFieldValue("is_auto_enabled", false);
          setFieldValue("auto_days", []);
          setFieldValue("username", null);
          setFieldValue("auto_time1", null);
          setFieldValue("auto_time2", null);
          setFieldValue("external_user_id", null);
        }
      }}
      onError={(message: string) => setErrors({ upload_media_url: message })}
    />
  );
}
