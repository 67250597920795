import cn from "classnames";
import React, { useState } from "react";
import { sanitizePunctuation, sanitizeXSSAndSQLInjection } from "../../utils";
import ErrorMessage from "../common/ErrorMessage";
import InfoTooltip from "../common/InfoTooltip";

const range = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, i) => start + i);
};

interface NumberTextAreaProps extends React.ComponentProps<"textarea"> {
  value: string;
  minLines?: number;
  maxLines?: number;
  maxHeight?: number;
  label?: string;
  tooltip?: string;
  errorMessage?: string;
  handleChange: (str: string) => void;
}

const LINE_HEIGHT = "20px";

const NumberTextArea: React.FC<NumberTextAreaProps> = ({
  className,
  value,
  handleChange,
  label,
  tooltip,
  errorMessage,
  minLines = 10,
  maxLines = 100,
  maxHeight = 226,
  ...props
}) => {
  const lineCount = value.trim() === "" ? 0 : value.split("\n").length;
  const [focus, setFocus] = useState(false);
  const [lines, setLines] = useState(minLines);
  const inputId = Math.random().toFixed(3);
  return (
    <div className="relative">
      {label && (
        <label
          htmlFor={inputId}
          className="mb-2 flex justify-between uppercase text-xs tracking-wide font-medium leading-6 text-gray-900"
        >
          <div className="flex items-center gap-2">
            <div className="flex">
              {label}
              {props.required && (
                <span className="text-red-500 align-super ml-0.5">*</span>
              )}
            </div>
            {tooltip && (
              <InfoTooltip
                id={`${inputId}-tooltip`}
                tooltip={tooltip}
              />
            )}
          </div>
        </label>
      )}
      <div
        className={cn(
          "bg-gray-100 border rounded-md",
          "overflow-hidden",
          {
            "border-primary": focus,
          },
          {
            "border-red-500": errorMessage,
          },
          className
        )}
        style={{ maxHeight, height: maxHeight }}
      >
        <div className="overflow-auto h-full w-full">
          <div className="flex">
            <div className="flex-none w-8 py-3">
              {range(1, lines).map((i) => {
                return (
                  <div
                    key={i}
                    className="text-center text-sm px-1 text-gray-500"
                    style={{ lineHeight: LINE_HEIGHT }}
                  >
                    {i}
                  </div>
                );
              })}
            </div>
            <textarea
              className={cn(
                "flex-auto border-0 text-sm tracking-wide",
                "bg-white focus:outline-none outline-none resize-none",
                "whitespace-nowrap p-3 overflow-y-hidden placeholder:text-gray-400",
                className
              )}
              style={{ lineHeight: LINE_HEIGHT }}
              rows={lines}
              value={value}
              onChange={(e) => {
                let cleanValue = e.currentTarget.value;
                if (cleanValue) {
                  cleanValue = decodeURIComponent(cleanValue);
                  cleanValue = sanitizeXSSAndSQLInjection(cleanValue);
                  cleanValue = sanitizePunctuation(cleanValue);
                  // space, single & double qoutes, multiple dots not allowed
                  cleanValue = cleanValue.replace(/[ "';]|\.{2,}/g, "");
                  // replace comma with new line character
                  cleanValue = cleanValue
                    .replaceAll(", ", "\n")
                    .replaceAll(",", "\n");

                  const lineCount = cleanValue.split("\n").length;
                  if (lineCount > maxLines) {
                    return;
                  }
                  if (lineCount > minLines) {
                    setLines(lineCount);
                  } else {
                    setLines(minLines);
                  }
                }

                handleChange(cleanValue);
              }}
              onFocus={() => {
                setFocus(true);
              }}
              onBlur={(e) => {
                handleChange(e.currentTarget.value.trim());
                setFocus(false);
              }}
              {...props}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between mt-1">
        {errorMessage && (
          <ErrorMessage
            errorMessage={errorMessage}
            name={`${inputId}-text-area`}
            className="normal-case text-xs"
          />
        )}
        <div className="absolute right-1 ml-auto text-xs text-gray-500">
          {lineCount} / {maxLines}
        </div>
      </div>
    </div>
  );
};

export default NumberTextArea;
