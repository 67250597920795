import { useFormikContext } from "formik";
import React from "react";
import CustomSelect, {
  OptionType,
} from "../../../../../components/form/CustomSelect";
import { ImageToneEnum } from "../../../../../enums/template";
import { Template } from "../../../../../models";

const imageToneOptions: OptionType[] = [
  {
    value: null,
    label: "None",
    description: "",
  },
  {
    value: ImageToneEnum.VIBRANT,
    label: "Vibrant",
    description: "Bold and striking colors that catch attention",
  },
  {
    value: ImageToneEnum.GRADIENT,
    label: "Gradient",
    description:
      "Smooth transitions between colors for a dynamic and engaging visual",
  },
  {
    value: ImageToneEnum.LIGHT,
    label: "Light",
    description: "Bright and airy tones for a fresh and uplifting feel",
  },
  {
    value: ImageToneEnum.BRIGHT,
    label: "Bright",
    description: "High contrast and saturation for a lively and energetic mood",
  },
  {
    value: ImageToneEnum.SOFT,
    label: "Soft",
    description:
      "Gentle contrast and subdued colors for a tranquil and calming ambiance",
  },
  {
    value: ImageToneEnum.MOODY,
    label: "Moody",
    description:
      "Dark tones and deep shadows for a dramatic and mysterious atmosphere",
  },
  {
    value: ImageToneEnum.VINTAGE,
    label: "Vintage",
    description: "Sepia tones and faded colors for a nostalgic and retro look",
  },
  {
    value: ImageToneEnum.EARTHY,
    label: "Earthy",
    description:
      "Browns, tans, and greens inspired by nature for a grounded feel",
  },
  {
    value: ImageToneEnum.HIGH_KEY,
    label: "High Key",
    description: "Bright, even lighting for a clean and minimalist aesthetic",
  },
  {
    value: ImageToneEnum.LOW_KEY,
    label: "Low Key",
    description: "Dark, shadowy lighting for a mysterious and dramatic effect",
  },
  {
    value: ImageToneEnum.GRAYSCALE,
    label: "Grayscale",
    description: "Black and white tones for a classic and timeless appeal",
  },
];

export default function ImageToneSelector() {
  const { values, setFieldValue } = useFormikContext<Partial<Template>>();
  return (
    <CustomSelect
      label="Image tone"
      placeholder="-"
      tooltip="Tone controls the mood and lightness or darkness of the image. Tone will established the mood and detail visibility via brightness, contrast, and shadow."
      options={imageToneOptions}
      selected={imageToneOptions.find(
        (option) => option.value === values?.image_tone_id
      )}
      setSelected={(option) => {
        setFieldValue("image_tone_id", option?.value || null);
      }}
    />
  );
}
