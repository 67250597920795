import { Turnstile as TurnstileComponent } from "@marsidev/react-turnstile";
import React from "react";
import { CF_TURNSTILE_SITE_KEY } from "../../config";

interface TurnstileWidgetProps {
  setToken: (token: string) => void;
}

const TurnstileWidget: React.FC<TurnstileWidgetProps> = ({ setToken }) => {
  if (!CF_TURNSTILE_SITE_KEY) {
    console.error("Turnstile siteKey is missing. Provide a valid siteKey.");
    return null;
  }

  return (
    <div>
      <TurnstileComponent
        options={{ theme: "light", size: "flexible" }}
        siteKey={CF_TURNSTILE_SITE_KEY}
        onSuccess={(token) => {
          setToken(token);
        }}
        onError={() => {
          console.error("Error loading Turnstile widget.");
        }}
        onExpire={() => {
          setToken(""); // Clear the token when expired
        }}
      />
    </div>
  );
};

export default TurnstileWidget;
