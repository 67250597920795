import { TrashIcon } from "@heroicons/react/24/outline";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import InfoTooltip from "../../components/common/InfoTooltip";
import PageHeading from "../../components/common/PageHeading";
import { Text } from "../../components/common/Typography";
import SpringContainer from "../../components/containers/SpringContainer";
import Button from "../../components/form/Button";
import CustomSelect, { OptionType } from "../../components/form/CustomSelect";
import Input from "../../components/form/Input";
import RadioGroup, { Option } from "../../components/form/RadioGroup";
import LoadingSpinner from "../../components/loaders/LoadingSpinner";
import { ROUTE_DESTINATIONS } from "../../constants/routes";
import {
  DestinationStatusEnum,
  DestinationTypeEnum,
} from "../../enums/destination";
import { Destination } from "../../models/destination";
import { useAppSelector } from "../../redux/hooks";
import {
  useCreateDestinationMutation,
  useGetDestinationByIdQuery,
  useTestDestinationMutation,
  useUpdateDestinationByIdMutation,
} from "../../redux/services/destinations";
import { RootState } from "../../redux/store";
import { ensureTrailingSlash } from "../../utils";
import TemplateContainer from "../TemplateForm/components/common/TemplateContainer";
import DestinationDeleteModal from "./components/DestinationDeleteModal";
import DestinationSaveModal from "./components/DestinationSaveModal";
import WordpressAppPassGuide from "./components/WordpressAppPassGuide";

const wordpressSchema = Yup.object().shape({
  name: Yup.string().required("Destination name is required"),
  destination: Yup.object().shape({
    url: Yup.string().required("Wordpress URL is required"),
    username: Yup.string().required("Admin Login Username is required"),
    // author_id: Yup.string().required("Author ID is required"),
    // category_id: Yup.string().required("Category ID is required"),
    secret_key: Yup.string()
      .required("App password is required")
      .test(
        "no-asterisk",
        "Please verify your app password",
        (value) => !value || !value.includes("**********")
      ),
    post_type: Yup.string()
      .nullable()
      .max(20, "Custom post type slug must be at most 20 characters long")
      .matches(
        /^[a-z0-9_-]+$/,
        "Custom post type slug may only contain lowercase letters, numbers, dashes, and underscores"
      ),
  }),
});

const initialValues: Destination = {
  name: "",
  destination_type_id: DestinationTypeEnum.WORDPRESS,
  destination: {
    url: "",
    username: "",
    author_id: null,
    category_id: null,
    secret_key: "",
    status: DestinationStatusEnum.DRAFT,
    post_type: "",
    random_post_hours: null,
  },
};

const statusOptions: Option[] = [
  {
    id: DestinationStatusEnum.DRAFT,
    name: "draft",
  },
  {
    id: DestinationStatusEnum.PENDING,
    name: "pending",
  },
  {
    id: DestinationStatusEnum.PUBLISHED,
    name: "published",
  },
];

export const randPostTimeOptions: OptionType[] = [
  {
    value: null,
    label: "-",
    description: "",
  },
  ...Array.from({ length: 12 }, (_, i) => ({
    value: i + 1,
    label: `${i + 1}`,
    description: "",
  })),
];

const DestinationConnect = () => {
  let { id: destinationId } = useParams(); // EDIT

  const navigate = useNavigate();
  const selectedAccount = useAppSelector(
    (state: RootState) => state.user.selectedAccount
  );

  const [
    createDestination,
    {
      isLoading: createLoading,
      isSuccess: createSuccess,
      isError: isErrorCreate,
      error: errorCreate,
    },
  ] = useCreateDestinationMutation();
  const [
    updateDestination,
    {
      isLoading: updateLoading,
      isSuccess: updateSuccess,
      isError: isErrorUpdate,
      error: errorUpdate,
    },
  ] = useUpdateDestinationByIdMutation();
  const [
    testDestination,
    {
      isLoading: testLoading,
      isError: isErrorTest,
      data: testSuccessData,
      error: errorMessageTest,
    },
  ] = useTestDestinationMutation();

  const { data: destination, refetch } = useGetDestinationByIdQuery(
    {
      account_id: selectedAccount?.account_id,
      destination_id: destinationId,
    },
    { skip: !destinationId }
  );

  const [formValues, setFormValues] = useState<Destination>();
  const [passwordGuide, setPasswordGuide] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [savedSecretKey, setSavedSecretKey] = useState(""); // initialize only on EDIT

  useEffect(() => {
    if (destinationId) {
      refetch();
    }
  }, [destinationId, refetch]);

  useEffect(() => {
    setFormValues(
      new Destination(
        destinationId && destination ? destination : initialValues
      )
    );
    setSavedSecretKey(destination?.destination?.secret_key);
  }, [destinationId, destination]);

  useEffect(() => {
    if (createSuccess || updateSuccess) {
      navigate(ROUTE_DESTINATIONS);
    }
  }, [createSuccess, updateSuccess, navigate]);

  useEffect(() => {
    if (isErrorCreate || isErrorUpdate || errorCreate || errorUpdate) {
      toast.error(
        ((errorCreate || errorUpdate) as any)?.data?.detail ||
          "Something went wrong."
      );
    }
  }, [isErrorCreate, isErrorUpdate, errorCreate, errorUpdate]);

  const handleSubmit = async (values: Destination, type: "test" | "save") => {
    if (!selectedAccount?.account_id) return;

    let payload: Partial<Destination & { account_id: string }> = {
      destination_type_id: DestinationTypeEnum.WORDPRESS,
      name: values.name,
      destination: {
        url: ensureTrailingSlash(values.destination.url),
        username: values.destination.username,
        secret_key: values.destination.secret_key,
        status: values.destination.status,
        author_id: values.destination.author_id ?? null,
        category_id: values.destination.category_id ?? null,
        post_type: values.destination.post_type ?? null,
        random_post_hours: values.destination.random_post_hours ?? null,
      },
      account_id: selectedAccount.account_id,
    };

    try {
      if (type === "test") {
        setFormValues(values);
        await testDestination(payload)
          .unwrap()
          .then(() => {
            setShowSaveModal(true);
          });
      } else {
        if (destinationId) {
          payload["destination_id"] = destinationId;
          payload["template_id_list"] = values.template_id_list || [];
          await updateDestination(payload).unwrap();
        } else {
          await createDestination(payload).unwrap();
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  if (destinationId && !destination) {
    return null;
  }

  return (
    <SpringContainer className="animate-fadeIn">
      {formValues && (
        <Formik
          initialValues={formValues}
          enableReinitialize={true}
          onSubmit={(values: Destination) => {
            if (values?.destination?.url) {
              values["destination"]["url"] = values?.destination?.url?.replace(
                /\/+$/,
                ""
              );
            }
            handleSubmit(values, "save");
          }}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={wordpressSchema}
        >
          {(props) => {
            const { values, errors, validateForm, setFieldValue } = props;
            return (
              <Form
                className="flex flex-col gap-6 flex-wrap"
                noValidate
              >
                <TemplateContainer
                  className="max-w-[60.5rem] rounded"
                  style={{ padding: 0 }}
                >
                  <div className="py-4 px-4 sm:px-6 relative bg-gray-50 rounded-t-lg">
                    <PageHeading heading="Configure WordPress" />
                    <Text className="text-gray-500 text-sm">
                      Provide your account details to establish a connection
                    </Text>
                    {destinationId && (
                      <Button
                        variant="light-danger"
                        tooltip="Delete wordpress connection"
                        className="group hover:border-red-500 hover:bg-red-50 absolute top-4 right-3"
                        onClick={() => setShowDeleteModal(true)}
                      >
                        <TrashIcon className="size-5 text-red-500" />
                      </Button>
                    )}
                  </div>
                  <div className="bg-gray-200 h-px w-full" />
                  <div className="py-4 px-4 sm:px-6 flex flex-col gap-6 mb-4">
                    <div className="flex flex-col md:flex-row gap-6">
                      <Input
                        id="name"
                        label="Destination Name"
                        name="name"
                        type="text"
                        required
                        placeholder="Name your destination"
                        className="max-w-md"
                        tooltip="Name your destination (e.g., “My WordPress Site”)"
                        value={values.name}
                        errorMessage={errors?.name}
                        errorPosition="bottom-left"
                        onChange={(e: any) =>
                          setFieldValue("name", e.target.value)
                        }
                      />

                      <Input
                        id="url"
                        label="URL"
                        name="destination.url"
                        type="text"
                        required
                        placeholder="https://mydomain.com"
                        className="max-w-md"
                        tooltip="The root domain for your WordPress Site (e.g. “https://mydomain.com”)"
                        value={values.destination.url}
                        errorMessage={errors?.destination?.url}
                        errorPosition="bottom-left"
                        onChange={(e: any) =>
                          setFieldValue("destination.url", e.target.value)
                        }
                      />
                    </div>
                    <div className="flex flex-col md:flex-row gap-6">
                      <Input
                        id="username"
                        label="Admin Login Username"
                        name="destination.username"
                        type="text"
                        required
                        placeholder="Your WordPress username"
                        className="max-w-md"
                        tooltip="Your WordPress username"
                        value={values.destination.username}
                        errorMessage={errors?.destination?.username}
                        errorPosition="bottom-left"
                        onChange={(e: any) =>
                          setFieldValue("destination.username", e.target.value)
                        }
                      />
                      <div className="relative w-full">
                        <Input
                          id="secret_key"
                          label="App Password"
                          name="secret_key"
                          type="text"
                          required
                          placeholder="App Password (Not your WordPress login password)"
                          className="max-w-md"
                          tooltip="IMPORTANT: This is not your WordPress login password, rather a separate password generated for API usage."
                          value={values.destination.secret_key}
                          errorMessage={errors?.destination?.secret_key}
                          errorPosition="bottom-left"
                          fontMono
                          onChange={(e: any) => {
                            setFieldValue(
                              "destination.secret_key",
                              e.target.value
                            );
                          }}
                          onKeyDown={(e: any) => {
                            if (
                              e.key === "Backspace" &&
                              e.target.value === savedSecretKey
                            ) {
                              setFieldValue("destination.secret_key", "");
                              e.preventDefault();
                            }
                          }}
                        />
                        <Button
                          paddingZero
                          type="button"
                          variant="transparent-primary"
                          className="absolute mt-1"
                          onClick={() => setPasswordGuide(!passwordGuide)}
                        >
                          How to generate
                        </Button>
                      </div>
                    </div>
                    {passwordGuide && (
                      <WordpressAppPassGuide
                        onRequestClose={() => setPasswordGuide(false)}
                      />
                    )}
                    <div>
                      <div className="mb-2 tracking-wide font-medium leading-6 text-gray-900 text-xs flex items-center gap-2 uppercase">
                        <div className="flex">
                          Status
                          <span className="text-red-500 align-super ml-0.5">
                            *
                          </span>
                        </div>
                        <InfoTooltip id="subject">
                          <div className="flex flex-col gap-2">
                            <div>
                              <b>draft:</b> Save the post as a draft — only
                              visible to users with admin access to the blog.
                            </div>
                            <div>
                              <b>pending:</b> Mark the post as pending editorial
                              approval.
                            </div>
                            <div>
                              <b>publish:</b> Publish the post — visible to
                              anyone with access to the blog.
                            </div>
                          </div>
                        </InfoTooltip>
                      </div>
                      <RadioGroup
                        options={statusOptions}
                        name="status"
                        selected={values?.destination?.status}
                        onClick={({ id }: Option) =>
                          setFieldValue("destination.status", id)
                        }
                      />
                    </div>
                    <div className="flex flex-col md:flex-row gap-6">
                      <Input
                        id="author_id"
                        label="Author ID"
                        name="destination.author_id"
                        type="number"
                        tooltip='This is the WordPress Author ID (a number) to whom you want the posts associated with. You can access this ID using a WordPress plugin such as "Reveal IDs."'
                        placeholder="Optional. WordPress Author ID (a number)"
                        className="max-w-md"
                        value={values?.destination?.author_id || ""}
                        errorMessage={errors?.destination?.author_id}
                        errorPosition="bottom-left"
                        onChange={(e: any) =>
                          setFieldValue("destination.author_id", e.target.value)
                        }
                      />

                      <Input
                        id="category_id"
                        label="Category ID"
                        name="destination.category_id"
                        type="number"
                        tooltip='This is the WordPress Category ID (a number) to assign your posts a category. You can access this ID using a WordPress plugin such as "Reveal IDs."'
                        placeholder="Optional. WordPress Category ID (a number)"
                        className="max-w-md"
                        value={values?.destination?.category_id || ""}
                        errorMessage={errors?.destination?.category_id}
                        errorPosition="bottom-left"
                        onChange={(e: any) =>
                          setFieldValue(
                            "destination.category_id",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <Input
                      id="post_type"
                      label="Custom post type slug"
                      name="destination.post_type"
                      type="text"
                      maxLength={20}
                      placeholder='Optional. Leave blank if you are unsure and articles will be posted to the default "posts"'
                      tooltip='Use the post type slug.  A custom post type in WordPress is a way to create content types that are different from standard posts and pages. The default post type for posting blog articles is "posts".'
                      value={values.destination.post_type ?? ""}
                      errorMessage={errors?.destination?.post_type}
                      errorPosition="bottom-left"
                      onChange={(e: any) => {
                        let inputValue = e.target.value
                          .toLowerCase()
                          .replace(/[^a-z0-9_-]/g, "")
                          .slice(0, 20);
                        setFieldValue("destination.post_type", inputValue);
                      }}
                    />
                    <div className="flex flex-col gap-1">
                      <label
                        htmlFor="rand-post-time-1"
                        className="flex gap-2 items-center  uppercase text-xs tracking-wide font-medium leading-6 text-gray-900"
                      >
                        Random Post Time
                        <InfoTooltip
                          id="rand-post-time"
                          tooltip="Search engines, such as Google, like frequently updated websites and random publish times."
                        />
                      </label>
                      <div className="text-[0.825rem] leading-5 text-gray-500">
                        Generate a random post time between the run time and
                        number of hours selected below.{" "}
                        <div className="">
                          Example: If your template run time is set 2:00 PM and
                          you select 6 hours, a random post time will be
                          selected between 2:00 PM and 8:00 PM.
                        </div>
                      </div>
                      <div className="text-xs text-gray-600"></div>
                      <CustomSelect
                        label="Hours"
                        placeholder="-"
                        className="max-w-[7rem]"
                        maxMenuHeight={100}
                        isClearable={Boolean(
                          values?.destination.random_post_hours
                        )}
                        options={randPostTimeOptions}
                        selected={randPostTimeOptions.find(
                          (option) =>
                            option.value ===
                            values?.destination.random_post_hours
                        )}
                        setSelected={(option) => {
                          setFieldValue(
                            "destination.random_post_hours",
                            option?.value || null
                          );
                        }}
                      />
                    </div>
                  </div>
                </TemplateContainer>
                {isErrorTest && (
                  <div className="text-red-500 text-sm my-1">
                    {(errorMessageTest as any)?.data?.detail ||
                      "Wordpress plugins or security may be interfering with your site connection. Please check your settings."}
                  </div>
                )}
                <div className="flex items-center justify-between max-w-5xl">
                  <div className="flex items-center gap-6">
                    <Button
                      variant="primary"
                      className="min-w-[8.75rem]"
                      onClick={() => {
                        validateForm().then(async (formErrors: any) => {
                          if (Object.keys(formErrors).length === 0) {
                            handleSubmit(values, "test");
                          }
                        });
                      }}
                    >
                      {testLoading ? (
                        <LoadingSpinner size="18px" />
                      ) : (
                        "Test Connection"
                      )}
                    </Button>
                    <Button
                      variant="default"
                      onClick={() => {
                        navigate(ROUTE_DESTINATIONS);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
      {showDeleteModal && (
        <DestinationDeleteModal
          destinationId={destinationId}
          onRequestClose={() => setShowDeleteModal(false)}
        />
      )}
      {showSaveModal && (
        <DestinationSaveModal
          message={testSuccessData?.message || ""}
          loading={createLoading || updateLoading}
          onSave={() => handleSubmit(formValues!, "save")}
          onRequestClose={() => setShowSaveModal(false)}
        />
      )}
    </SpringContainer>
  );
};

export default DestinationConnect;
