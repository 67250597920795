import React from "react";
import { useNavigate } from "react-router-dom";
import GetStartedTemplateBanner from "../../components/features/GetStartedTemplateBanner";
import LoadingSpinner from "../../components/loaders/LoadingSpinner";
import { ROUTE_TEMPLATES_CREATE } from "../../constants/routes";
import { useAppSelector } from "../../redux/hooks";
import { useGetAllArticlesQuery } from "../../redux/services/article";
import { RootState } from "../../redux/store";

const TemplateCreateRedirect = () => {
  const navigate = useNavigate();
  const selectedAccount = useAppSelector(
    (state: RootState) => state.user.selectedAccount
  );
  const accountId = selectedAccount?.account_id ?? null;

  const { data, isSuccess, isFetching } = useGetAllArticlesQuery(
    {
      account_id: accountId ?? "",
      offset: 0,
      limit: 1,
      include_total_count: true,
    },
    { skip: !accountId, refetchOnMountOrArgChange: true }
  );

  if (isFetching) {
    return <LoadingSpinner size="14px" />;
  }

  // Conditional redirect based on total_count
  if (isSuccess && data?.total_count > 0) {
    navigate(ROUTE_TEMPLATES_CREATE);
    return null; // Prevent rendering ArticleCreateHero after redirect
  }

  return <GetStartedTemplateBanner />;
};

export default TemplateCreateRedirect;
