import { useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/store";
import { browserTimeZone } from "../utils";

const useSelectedAccount = () => {
  const selectedAccount = useAppSelector(
    (state: RootState) => state.user.selectedAccount
  );
  const accountId = selectedAccount?.account_id || null;
  const timezone = selectedAccount?.timezone || browserTimeZone;

  return { selectedAccount, accountId, timezone };
};

export default useSelectedAccount;
