import { useFormikContext } from "formik";
import React, { useMemo } from "react";
import RadioGroup from "../../../../components/form/RadioGroup";
import { SourceEnum } from "../../../../enums/template";
import useSelectedAccount from "../../../../hooks/useSelectedAccount";
import { Template } from "../../../../models";
import { getSourceSVG } from "../../../../utils";

const PlatformSVG = ({ title, src }: { title: string; src: any }) => (
  <div className="flex gap-1 items-center">
    <img
      src={src}
      alt={title}
      className="w-4 h-4"
    />
    {title}
  </div>
);

let defaultPlatforms = [
  {
    id: SourceEnum.INSTAGRAM_REELS,
    name: (
      <PlatformSVG
        title="Reels"
        src={getSourceSVG(SourceEnum.INSTAGRAM_REELS)}
      />
    ),
  },
  {
    id: SourceEnum.YOUTUBE_SHORTS,
    name: (
      <PlatformSVG
        title="Shorts"
        src={getSourceSVG(SourceEnum.YOUTUBE_SHORTS)}
      />
    ),
  },
  {
    id: SourceEnum.YOUTUBE_VIDEOS,
    name: (
      <PlatformSVG
        title="Videos"
        src={getSourceSVG(SourceEnum.YOUTUBE_VIDEOS)}
      />
    ),
  },
  {
    id: SourceEnum.APPLE_PODCASTS,
    name: (
      <PlatformSVG
        title="Podcast"
        src={getSourceSVG(SourceEnum.APPLE_PODCASTS)}
      />
    ),
  },
  {
    id: SourceEnum.TEXT_BLOCK,
    name: (
      <PlatformSVG
        title="Custom Text"
        src={getSourceSVG(SourceEnum.TEXT_BLOCK)}
      />
    ),
  },
  {
    id: SourceEnum.UPLOAD_MEDIA,
    name: (
      <PlatformSVG
        title="Upload Media"
        src={getSourceSVG(SourceEnum.UPLOAD_MEDIA)}
      />
    ),
  },
];

const RSSFeeds = {
  id: SourceEnum.RSS_FEEDS,
  name: (
    <PlatformSVG
      title="RSS Feeds"
      src={getSourceSVG(SourceEnum.RSS_FEEDS)}
    />
  ),
};

const SourceSelector = React.memo(() => {
  const { values, setFieldValue } = useFormikContext<Partial<Template>>();
  const { selectedAccount } = useSelectedAccount();

  // Use useMemo to prevent unnecessary recalculations
  const platforms = useMemo(() => {
    let updatedPlatforms = [...defaultPlatforms];

    if (selectedAccount?.has_rss_access) {
      updatedPlatforms.splice(4, 0, RSSFeeds);
    }

    return updatedPlatforms;
  }, [selectedAccount?.has_rss_access]);

  return (
    <div className="">
      <label
        htmlFor="platform_selector"
        className="mb-1 tracking-wide font-medium leading-6 text-gray-900 text-xs flex items-center gap-2 uppercase"
      >
        Platform
      </label>

      <RadioGroup
        options={platforms}
        name="source_id"
        selected={values?.source_id ?? SourceEnum.INSTAGRAM_REELS}
        onClick={({ id }) => {
          setFieldValue("source_id", id);
          if (id === SourceEnum.RSS_FEEDS) {
            setFieldValue("rss_get_random_entry", true);
          }
        }}
      />
    </div>
  );
});

export default SourceSelector;
