import { useFormikContext } from "formik";
import React from "react";
import Input from "../../../../../components/form/Input";
import { SourceEnum } from "../../../../../enums/template";
import { Template } from "../../../../../models";
import {
  getAutoSourceLabel,
  getUsernamePlaceholder,
  getUsernameTooltip,
} from "../../../../../utils";

export default function MediaUserNameInput() {
  const { values, errors, setFieldValue } =
    useFormikContext<Partial<Template>>();
  return (
    <Input
      label={getAutoSourceLabel(values.source_id as SourceEnum)}
      name="username1"
      value={values?.username ?? ""}
      errorMessage={errors?.username as string}
      errorPosition="bottom-left"
      placeholder={getUsernamePlaceholder(values.source_id as SourceEnum)}
      tooltip={getUsernameTooltip(values.source_id as SourceEnum)}
      onChange={({ target: { value } }) => {
        setFieldValue("username", value.trim());
      }}
    />
  );
}
