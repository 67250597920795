import { useFormikContext } from "formik";
import React from "react";
import Input from "../../../../components/form/Input";
import { Template } from "../../../../models";

export default function TemplateName() {
  const { values, errors, setFieldValue } =
    useFormikContext<Partial<Template>>();

  return (
    <Input
      name="name"
      label="Template Name"
      placeholder="A meaningful name for this article group. Templates can generate one or more articles"
      tooltip="From this template your articles will be generated. Provide a meaningful name, for example, Achieve Personal Growth or Home Design Tips."
      value={values?.name!}
      errorMessage={errors["name"] as string}
      errorPosition="bottom-left"
      onChange={({ target: { value } }) => setFieldValue("name", value)}
      onBlur={({ target: { value } }) => {
        setFieldValue("name", value.trim());
      }}
    />
  );
}
