import { useFormikContext } from "formik";
import React from "react";
import { FaqQuestionPrefixEnum, NoOfFaqEnum } from "../../../../enums/template";
import { Template } from "../../../../models";
import SwitchContainer from "../common/SwitchContainer";

export default function IncludeFAQToggle() {
  const { values, setFieldValue } = useFormikContext<Partial<Template>>();

  return (
    <SwitchContainer
      title="Include FAQs"
      tooltip='Add a "Frequently Asked Questions" section to the bottom of the article.'
      enabled={Boolean(values?.include_faq)}
      setEnabled={() => {
        setFieldValue("include_faq", !values?.include_faq);
        if (!values?.include_faq && !values.faq_question_prefix_id) {
          setFieldValue(
            "faq_question_prefix_id",
            FaqQuestionPrefixEnum.Q_LETTER
          );
        }
        if (!values?.include_faq && !values.faq_count) {
          setFieldValue("faq_count", NoOfFaqEnum.THREE);
        }
      }}
    />
  );
}
