import React from "react";
import useUser from "../../../../hooks/useUser";
import AdditionalSettings from "./AdditionalSettings/AdditionalSettings";
import BrandSettings from "./BrandSettings/BrandSettings";
import Engine from "./Engine";
import Faq from "./FaqSettings/Faq";
import ImageSettings from "./ImageSettings/ImageSettings";

const AdvancedSettings = () => {
  const { user } = useUser();
  return (
    <div className="animate-fadeIn space-y-6">
      <ImageSettings />
      <Faq />
      <BrandSettings />
      <AdditionalSettings />
      {user?.is_super && <Engine />}
    </div>
  );
};

export default AdvancedSettings;
