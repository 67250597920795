import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { API_CACHING_TAGS } from "../../constants/api";
import { BASE_URL } from "../apiEndPoints";
import { logout } from "../slices/authSlice";
import { RootState } from "../store";

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const authToken = (getState() as RootState).auth.token;
    if (authToken) {
      headers.set("authorization", `Bearer ${authToken}`);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  // logout on 401 except destinatin connection test path
  if (result.error && result.error.status === 401) {
    const isDestinationTestPath = (args as FetchArgs)?.url?.includes(
      "/destination-connection-test"
    );
    if (!isDestinationTestPath) {
      api.dispatch(logout());
    }
  }

  return result;
};

export const api = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: API_CACHING_TAGS,
  endpoints: () => ({}),
});
