import { useFormikContext } from "formik";
import React from "react";
import { Template } from "../../../../../models";
import AutomationScheduleSettings from "./AutomationScheduleSettings";
import AutomationToggle from "./AutomationToggle";
import MediaLinkInput from "./MediaLinkInput";
import MediaUserNameInput from "./MediaUserNameInput";

export default function MediaAndAutomationSettings() {
  const { values } = useFormikContext<Partial<Template>>();
  return (
    <div className="space-y-6">
      <AutomationToggle />
      {values?.is_auto_enabled ? (
        <>
          <MediaUserNameInput />
          <AutomationScheduleSettings />
        </>
      ) : (
        <MediaLinkInput />
      )}
    </div>
  );
}
