import { useFormikContext } from "formik";
import React from "react";
import CustomSelect, {
  OptionType,
} from "../../../../../components/form/CustomSelect";
import { BrandPersonaEnum } from "../../../../../enums/template";
import { Template } from "../../../../../models";

const brandPersonaOptions: OptionType[] = [
  {
    value: null,
    label: "None",
    description: "",
  },
  {
    value: BrandPersonaEnum.PROFESSIONAL,
    label: "Professional",
    description: "Knowledgeable, pragmatic, supportive, and impartial",
  },
  {
    value: BrandPersonaEnum.EXPERT,
    label: "Expert",
    description: "Knowledgeable, authoritative, and confident",
  },
  {
    value: BrandPersonaEnum.PHILOSOPHER,
    label: "Philosopher",
    description: "Ethical, thought-provoking, encourages critical thinking",
  },
  {
    value: BrandPersonaEnum.FRIEND,
    label: "Friend",
    description: "Warm, approachable, and relatable",
  },
  {
    value: BrandPersonaEnum.ENTERTAINER,
    label: "Entertainer",
    description: "Fun, humorous, and engaging",
  },
  {
    value: BrandPersonaEnum.SAGE,
    label: "Sage",
    description: "Wise, insightful, and thought-provoking",
  },
  {
    value: BrandPersonaEnum.REBEL,
    label: "Rebel",
    description: "Bold, daring, and unconventional",
  },
  {
    value: BrandPersonaEnum.CAREGIVER,
    label: "Caregiver",
    description: "Nurturing, supportive, and empathetic",
  },
];
export default function BrandPersonaSelector() {
  const { values, setFieldValue } = useFormikContext<Partial<Template>>();
  return (
    <div className="mb-6">
      <CustomSelect
        label="Brand Persona"
        placeholder="-"
        options={brandPersonaOptions}
        selected={brandPersonaOptions.find(
          (option) => option.value === values?.brand_persona_id
        )}
        tooltip="Refers to the identity, personality traits, and characteristics that your brand embodies."
        setSelected={(option) => {
          setFieldValue(
            "brand_persona_id",
            (option?.value as BrandPersonaEnum) || null
          );
        }}
      />
    </div>
  );
}
