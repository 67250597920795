import React from "react";
import { useNavigate } from "react-router-dom";
import WordpressSVG from "../../../assets/svg/wordpress.svg";
import Button from "../../../components/form/Button";
import { ROUTE_DESTINATIONS_CREATE } from "../../../constants/routes";

const WordpressCard = () => {
  const navigate = useNavigate();
  return (
    <div className="animate-fadeIn border rounded max-w-xs p-4 flex flex-col gap-4">
      <img
        alt="wordpress-logo"
        src={WordpressSVG}
        className="size-16"
      />
      <div className="font-semibold">WordPress</div>
      <div className="text-gray-700 text-sm">
        Share your generated articles right into your wordpress account
      </div>
      <Button
        variant="primary"
        onClick={() => navigate(ROUTE_DESTINATIONS_CREATE)}
      >
        Configure
      </Button>
    </div>
  );
};

export default WordpressCard;
