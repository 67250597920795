import pica from "pica";
import React, { createRef, useState } from "react";
import ReactAvatar from "react-avatar";
import AvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import Modal from "../common/Modal";
import { Heading, Text } from "../common/Typography";
import Button from "../form/Button";
import Slider from "../form/Slider/Slider";

const IMAGE_MAX_DIMENSION = 200;

interface MediaUploaderProps {
  src: string;
  heading: string;
  loading: boolean;
  onClose: (open: boolean) => void;
  onUpload: (imageFile: File, url: string) => void;
  onDelete?: () => void;
}

const MediaUploader = ({
  heading,
  src,
  loading,
  onUpload,
  onClose,
  onDelete,
}: MediaUploaderProps) => {
  const editor = createRef<AvatarEditor>();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [uploadedFile, setUploadedFile] = useState<string | null>(null);
  const [filePreview, setFilePreview] = useState<string | null>(null);

  const handleDrop = (files: File[]) => {
    if (files.length === 1) {
      setUploadedFile(window.URL.createObjectURL(files[0]));
    } else {
      alert("Invalid file upload. Please upload only one file.");
    }
  };

  const handleUpload = () => {
    if (editor?.current) {
      const canvas = editor.current?.getImage();
      resizeImage(canvas).then((blob) => {
        const uploadImage = new File(
          [blob],
          `profile-${new Date().getTime()}`,
          { type: "image/png", lastModified: Date.now() }
        );
        onUpload(uploadImage, window.URL.createObjectURL(blob));
        onClose(false);
      });
    }
  };

  const resizeImage = (canvas: HTMLCanvasElement) => {
    const offScreenCanvas = document.createElement("canvas");
    offScreenCanvas.width = IMAGE_MAX_DIMENSION;
    offScreenCanvas.height = IMAGE_MAX_DIMENSION;
    const picaInstance = pica();
    return picaInstance
      .resize(canvas, offScreenCanvas, {
        unsharpAmount: 80,
        unsharpRadius: 0.6,
        unsharpThreshold: 2,
      })
      .then((result) => {
        return picaInstance.toBlob(result, "image/png", 0.9);
      });
  };
  return (
    <Modal
      className="p-6"
      isOpen={true}
      width={350}
      onRequestClose={() => {
        onClose(true);
        setScale(1);
        setRotate(0);
        setUploadedFile(null);
        setFilePreview(null);
      }}
    >
      <Heading
        size="xl"
        className="mb-8"
        width="100%"
      >
        {heading}
      </Heading>
      <div className="mb-8">
        <Dropzone
          disabled={loading}
          accept={{
            "image/*": [".jpg", ".jpeg", ".png", ".webp"],
          }}
          maxFiles={1}
          multiple={false}
          maxSize={5000000}
          onDropAccepted={handleDrop}
          onDropRejected={() => {
            alert(
              "Invalid file format or size. Please upload a valid image file."
            );
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              className="border-2 bg-gray-100 rounded-md border-gray-200 border-dashed p-6 cursor-pointer flex justify-center outline-none focus:outline-none"
            >
              <input {...getInputProps()} />
              <div className="h-15 text-sm uppercase tracking-wider text-gray-400 font-semibold flex items-center">
                Click or Drag Image
              </div>
            </div>
          )}
        </Dropzone>
      </div>
      {(uploadedFile || src) && (
        <div>
          <div className="w-max mx-auto">
            <AvatarEditor
              ref={editor}
              crossOrigin="anonymous"
              className="border border-gray-200 border-solid"
              image={uploadedFile || src || ""}
              width={160}
              height={160}
              borderRadius={9999}
              border={30}
              color={[0, 0, 0, 0.6]} // RGBA
              scale={scale}
              rotate={rotate}
              disableBoundaryChecks={true}
            />
          </div>
          <div className="flex-1 p-4">
            <Text>Scale</Text>
            <Slider
              max={10}
              min={0.5}
              step={0.1}
              value={scale}
              onChange={(value) => {
                if (typeof value === "number") {
                  setScale(value);
                }
              }}
              disabled={loading}
            />
          </div>
        </div>
      )}

      {filePreview && (
        <div className="flex justify-center mt-4">
          <ReactAvatar
            round
            size="80px"
            src={filePreview}
          />
        </div>
      )}
      <div className="flex">
        <Button
          variant="primary"
          loading={loading}
          type="button"
          onClick={handleUpload}
        >
          Save
        </Button>
        {onDelete && src && (
          <div className="ml-auto">
            <Button
              type="button"
              variant="danger"
              loading={loading}
              onClick={() => {
                onDelete();
                onClose(false);
              }}
            >
              Delete
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default MediaUploader;
