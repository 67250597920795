import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import React, {
  ChangeEvent,
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
  useCallback,
  useState,
} from "react";
import {
  generateRandomNumber,
  sanitizePunctuation,
  sanitizeXSSAndSQLInjection,
} from "../../utils";
import ErrorMessage from "../common/ErrorMessage";
import InfoTooltip from "../common/InfoTooltip";
import LoadingSpinner from "../loaders/LoadingSpinner";
import Button from "./Button";

type ErrorPosition = "top-right" | "top-left" | "bottom-right" | "bottom-left";
interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  label?: any;
  search?: boolean;
  tooltip?: string;
  className?: string;
  fontMono?: boolean;
  loading?: boolean;
  errorMessage?: string;
  errorPosition?: ErrorPosition;
  width?: string | number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Input = ({
  id,
  type = "text",
  label,
  errorMessage,
  errorPosition = "top-right",
  tooltip,
  className,
  fontMono,
  search = false,
  loading = false,
  width = "100%",
  onChange,
  ...rest
}: InputProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const inputType: HTMLInputTypeAttribute = showPassword ? "text" : type;
  const inputId = id ?? `input-${generateRandomNumber(6)}`;
  const inputName = rest.name ?? `input-${generateRandomNumber(6)}`;

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let cleanValue = e.target.value || "";

      if (type === "password") {
        // DO NOTHING
      } else {
        cleanValue = sanitizeXSSAndSQLInjection(cleanValue);
        cleanValue = sanitizePunctuation(cleanValue);
      }

      e.target.value = cleanValue;
      onChange(e);
    },
    [onChange, type]
  );

  return (
    <div
      className={classNames("w-full", className)}
      style={{ width: width }}
    >
      <div className="sm:col-span-3">
        {label && (
          <label
            htmlFor={inputId}
            className="mb-2 flex justify-between uppercase text-xs tracking-wide font-medium leading-6 text-gray-900"
          >
            <div className="flex items-center gap-2">
              <div className="flex">
                {label}
                {rest.required && (
                  <span className="text-red-500 align-super ml-0.5">*</span>
                )}
              </div>
              {tooltip && (
                <InfoTooltip
                  id={`${inputId}-tooltip`}
                  tooltip={tooltip}
                />
              )}
            </div>

            {errorMessage && errorPosition === "top-right" && (
              <ErrorMessage
                errorMessage={errorMessage}
                name={inputName}
              />
            )}
          </label>
        )}

        <div className="relative flex items-center rounded-md">
          {search && (
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
          )}

          <input
            {...rest}
            value={rest.value || ""}
            type={inputType}
            id={id ?? inputName}
            name={inputName}
            autoComplete={inputName}
            onChange={handleChange}
            className={classNames(
              "rounded-md text-gray-900 block w-full border-0 py-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6",
              {
                "ring-red-500": errorMessage,
              },
              {
                "font-mono": fontMono,
              },
              "disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 disabled:ring-gray-200 disabled:placeholder:opacity-0",
              {
                "ps-10": search,
              }
            )}
          />

          {loading && (
            <div className="absolute inset-y-0 end-3 flex items-center ps-3 pointer-events-none cursor-pointer">
              <LoadingSpinner />
            </div>
          )}

          {type === "password" && (
            <Button
              variant="transparent"
              className="absolute inset-y-0 right-0 group flex items-center justify-center min-w-[2.5rem]"
              onClick={(e: any) => {
                e.stopPropagation();
                setShowPassword((prevShowPassword) => !prevShowPassword);
              }}
            >
              {!showPassword && (
                <EyeSlashIcon className="w-4 h-4 text-gray-500 group-hover:text-gray-700" />
              )}
              {showPassword && (
                <EyeIcon className="w-4 h-4 text-gray-500 group-hover:text-gray-700" />
              )}
            </Button>
          )}
        </div>

        {errorMessage && errorPosition === "bottom-left" && (
          <ErrorMessage
            errorMessage={errorMessage}
            name={inputName}
          />
        )}
      </div>
    </div>
  );
};

export default Input;
