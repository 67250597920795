import { Menu, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/solid";
import classNames from "classnames";
import React, { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_PROFILE } from "../../constants/routes";
import useSelectedAccount from "../../hooks/useSelectedAccount";
import { Account } from "../../models";
import { useAppDispatch } from "../../redux/hooks";
import { useGetUserQuery } from "../../redux/services/user";
import { setSelectedAccount } from "../../redux/slices/userSlice";
import Avatar from "../common/Avatar";
import Button from "../form/Button";

interface AccountDropdownProps {
  sidebarOpen: boolean;
}

const AccountDropdown: React.FC<AccountDropdownProps> = ({ sidebarOpen }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data: user } = useGetUserQuery();
  const { selectedAccount } = useSelectedAccount();

  useEffect(() => {
    const accounts = user?.accounts;
    if (accounts && accounts.length > 0) {
      dispatch(setSelectedAccount(accounts[0]));
    }
  }, [user?.accounts, dispatch]);

  const account = selectedAccount || user?.accounts[0];

  return (
    <Menu
      as="div"
      className="relative inline-block text-left w-full overflow-visible"
    >
      {({ open }) => (
        <>
          <Menu.Button
            className={classNames(
              "w-full",
              sidebarOpen ? "min-w-[10rem]" : "min-w-[2rem]"
            )}
            onClick={() => {}}
          >
            <div className="flex items-center gap-2 text-gray-900 group rounded py-2 hover:bg-gray-200 w-full px-1">
              <Avatar
                className="flex-shrink-0"
                round
                alt={account?.company_name}
                color="lightgray"
                src={account?.company_logo_url || ""}
                size="24px"
              />
              {sidebarOpen && (
                <>
                  <div className="max-w-40 truncate text-sm">
                    {account?.company_name}
                  </div>
                  {open ? (
                    <ChevronUpIcon className="ml-auto h-4 w-4 text-gray-500" />
                  ) : (
                    <ChevronDownIcon className="ml-auto h-4 w-4 text-gray-500" />
                  )}
                </>
              )}
            </div>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute left-0 z-50 mt-2 w-full min-w-52 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {user?.accounts?.map((account: Account) => {
                  const { account_id, company_name, company_logo_url } =
                    account;
                  return (
                    <Menu.Item key={account_id}>
                      {() => (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            dispatch(setSelectedAccount(account));
                          }}
                          className={classNames(
                            "w-full px-3 py-2 flex items-center gap-2 hover:bg-gray-100 cursor-pointer",
                            {
                              "text-gray-700":
                                account_id !== selectedAccount?.account_id,
                            }
                          )}
                        >
                          <Avatar
                            round
                            alt={company_name}
                            color="lightgray"
                            src={company_logo_url || ""}
                            size="24px"
                          />
                          <div className="max-w-[15rem] text-sm truncate">
                            {company_name}
                          </div>
                          <Button
                            paddingZero
                            variant="transparent-primary"
                            className="text-sm ml-auto group"
                            onClick={() => {
                              navigate(ROUTE_PROFILE);
                            }}
                          >
                            <PencilSquareIcon className=" size-[18px] text-gray-500 group-hover:text-primary" />
                          </Button>
                        </div>
                      )}
                    </Menu.Item>
                  );
                })}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default AccountDropdown;
