import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageHeading from "../../components/common/PageHeading";
import { Pagination } from "../../components/common/Table";
import SpringContainer from "../../components/containers/SpringContainer";
import GetStartedTemplateBanner from "../../components/features/GetStartedTemplateBanner";
import Button from "../../components/form/Button";
import Input from "../../components/form/Input";
import { ROUTE_TEMPLATES_CREATE } from "../../constants/routes";
import { useTokensFinished } from "../../hooks/useTokensFinished";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useGetDashboardQuery } from "../../redux/services/dashboard";
import { useGetTemplatesQuery } from "../../redux/services/templates";
import { setUpgradeModal } from "../../redux/slices/userSlice";
import { RootState } from "../../redux/store";
import { browserTimeZone } from "../../utils";
import TemplateTable from "./components/TemplateTable";

const limit = 20;
const Templates = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isTokensFinished } = useTokensFinished();

  const selectedAccount = useAppSelector(
    (state: RootState) => state.user.selectedAccount
  );
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = React.useState("");

  const [totalTemplates, setTotalTemplates] = useState(0);
  const [searchTotalTemplate, setSearchTotalTemplate] = useState(0);
  const [offset, setOffset] = useState(0);
  const accountId = selectedAccount?.account_id || null;

  const { data, isLoading, refetch, isFetching, isUninitialized } =
    useGetTemplatesQuery(
      {
        account_id: accountId || "",
        offset: offset,
        limit: limit,
        search: debouncedSearch || "",
        include_total_count: true,
      },
      { skip: !accountId }
    );

  const { data: dashboardData } = useGetDashboardQuery(
    {
      account_id: accountId || "",
      timezone: selectedAccount?.timezone || browserTimeZone,
    },
    { skip: !accountId }
  );

  useEffect(() => {
    if (accountId) {
      refetch();
    }
  }, [refetch, accountId, offset]);

  useEffect(() => {
    if (!totalTemplates && data?.total_count !== undefined) {
      setTotalTemplates(data.total_count);
    }
  }, [data?.total_count, totalTemplates, setTotalTemplates]);

  useEffect(() => {
    if (debouncedSearch && data?.total_count !== undefined) {
      setSearchTotalTemplate(data.total_count);
    } else {
      setSearchTotalTemplate(0);
    }
  }, [data?.total_count, debouncedSearch, setSearchTotalTemplate]);

  const templates = data?.data || [];
  const templatesExists = Boolean(templates?.length > 0);

  if (isUninitialized || isLoading) {
    return null;
  }

  return (
    <SpringContainer>
      <div className="bg-white h-full">
        <div className="flex justify-between items-center md:mb-4">
          <div className="flex flex-col lg:flex-row gap-2 lg:items-center">
            <PageHeading
              heading="Article Template Settings"
              tooltip="Refresh"
              loading={isFetching}
              refetch={refetch}
            />
            <div className="hidden md:block">
              <Input
                search
                value={search}
                placeholder="Search by title"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                onBlur={() => setDebouncedSearch(search)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === "Tab") {
                    setDebouncedSearch(search);
                  }
                }}
                loading={isFetching}
                width={350}
              />
            </div>
          </div>
          {templatesExists && (
            <Button
              variant="primary"
              type="submit"
              onClick={() => {
                if (isTokensFinished) {
                  dispatch(setUpgradeModal(true));
                } else {
                  navigate(ROUTE_TEMPLATES_CREATE);
                }
              }}
            >
              New
            </Button>
          )}
        </div>
        <div className="block md:hidden my-2 mb-4">
          <Input
            search
            value={search}
            placeholder="Search by title"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            onBlur={() => setDebouncedSearch(search)}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === "Tab") {
                setDebouncedSearch(search);
              }
            }}
          />
        </div>
        {!templatesExists && !debouncedSearch && <GetStartedTemplateBanner />}
        {!templatesExists && debouncedSearch && (
          <div className="text-sm text-gray-700">
            No Article Template Settings found
          </div>
        )}
        {templatesExists && (
          <>
            <TemplateTable templates={templates} />
            {(debouncedSearch
              ? searchTotalTemplate > limit
              : totalTemplates > limit) && (
              <Pagination
                offset={offset}
                limit={limit}
                total={debouncedSearch ? searchTotalTemplate : totalTemplates}
                setOffset={setOffset}
              />
            )}
          </>
        )}
      </div>
    </SpringContainer>
  );
};

export default Templates;
