import React from "react";
import SpringContainer from "../../components/containers/SpringContainer";
import Accounts from "./Accounts";
import DangerZone from "./DangerZone";
import User from "./User";

export default function Profile() {
  return (
    <SpringContainer className="h-full flex flex-col gap-6 max-w-7xl">
      <User />
      <Accounts />
      <DangerZone />
    </SpringContainer>
  );
}
