import React from "react";
import Modal from "../common/Modal";
import Button from "../form/Button";

type ConfirmationModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  onConfirm: () => void;
  message: string | React.ReactNode;
  title?: string;
  confirmText?: string;
  cancelText?: string;
  width?: number;
};

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onRequestClose,
  onConfirm,
  message,
  title = "Confirm Action",
  confirmText = "Confirm",
  cancelText = "Cancel",
  width = 400,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      width={width}
    >
      <div className="p-6">
        {title && <h2 className="text-xl font-semibold mb-4">{title}</h2>}
        <p className="mb-6">{message}</p>
        <div className="flex justify-end space-x-4">
          <Button
            onClick={onRequestClose}
            variant="secondary"
          >
            {cancelText}
          </Button>
          <Button
            onClick={onConfirm}
            variant="primary"
          >
            {confirmText}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
