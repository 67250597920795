import { useAppSelector } from "../redux/hooks";
import { useGetDashboardQuery } from "../redux/services/dashboard";
import { RootState } from "../redux/store";
import { browserTimeZone } from "../utils";

export const useTokensFinished = () => {
  const selectedAccount = useAppSelector(
    (state: RootState) => state.user.selectedAccount
  );

  const accountId = selectedAccount?.account_id ?? null;

  const { data } = useGetDashboardQuery(
    {
      account_id: accountId ?? "",
      timezone: selectedAccount?.timezone ?? browserTimeZone,
    },
    { skip: !accountId }
  );

  return { isTokensFinished: data?.tokens_remaining < 1 };
};
