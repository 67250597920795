import { useFormikContext } from "formik";
import React from "react";
import RadioGroup, { Option } from "../../../../../components/form/RadioGroup";
import { NoOfFaqEnum } from "../../../../../enums/template";
import { Template } from "../../../../../models";

const noOffaqOptions = [
  {
    id: NoOfFaqEnum.TWO,
    name: "2",
  },
  {
    id: NoOfFaqEnum.THREE,
    name: "3",
  },
  {
    id: NoOfFaqEnum.FOUR,
    name: "4",
  },
  {
    id: NoOfFaqEnum.FIVE,
    name: "5",
  },
  {
    id: NoOfFaqEnum.SIX,
    name: "6",
  },
];

export default function FaqCountSelector() {
  const { values, setFieldValue } = useFormikContext<Partial<Template>>();
  return (
    <div className="w-max">
      <h3 className="mb-2 tracking-wide font-medium leading-6 text-gray-900 text-xs flex items-center gap-2 uppercase">
        How many FAQs to add?
      </h3>
      <RadioGroup
        options={noOffaqOptions}
        name="faq_count"
        selected={values?.faq_count}
        onClick={({ id }: Option) => setFieldValue("faq_count", id)}
      />
    </div>
  );
}
