import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParam } from "react-use";
import Header from "../../components/layouts/Header";
import LoadingSpinner from "../../components/loaders/LoadingSpinner";
import {
  STRIPE_PRICING_TABLE_PUBLISHABLE_KEY,
  STRIPE_RENEW_PRICING_TABLE_ID,
} from "../../config";
import { ROUTE_PLANS, ROUTE_ROOT } from "../../constants/routes";
import { useAppSelector } from "../../redux/hooks";
import { useGetSubscriptionQuery } from "../../redux/services/subscription";
import { useGetUserQuery } from "../../redux/services/user";
import { RootState } from "../../redux/store";
import { createCustomerSession } from "../../stripe";

declare global {
  interface Window {
    Rewardful: {
      referral: string;
    };
  }
  namespace JSX {
    interface IntrinsicElements {
      "stripe-pricing-table": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

// TODO: on verify redirect no retry - use query param
// https://dashboard.stripe.com/pricing-tables
const RenewPlans = () => {
  const navigate = useNavigate();
  const subscribed = useSearchParam("subscribed");
  const authToken = useAppSelector((state: RootState) => state.auth.token);
  const [clientSecret, setClientSecret] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const [loading, setLoading] = useState(false);

  // User
  const { data: user } = useGetUserQuery();
  const selectedAccount = user?.accounts[0] || null;
  const accountId = selectedAccount?.account_id || null;
  const stripeCustomerId = selectedAccount?.stripe_customer_id || null;

  // Subscription
  const {
    data: subscription,
    isSuccess,
    refetch,
    isUninitialized,
  } = useGetSubscriptionQuery(
    {
      account_id: accountId || "",
    },
    { skip: !accountId }
  );

  const getClientSecret = useCallback(async () => {
    if (!authToken) return;

    await createCustomerSession(stripeCustomerId, authToken).then((res) => {
      if (res?.client_secret) {
        setClientSecret(res.client_secret);
      }
    });
  }, [authToken, stripeCustomerId]);

  useEffect(() => {
    if (!isSuccess) return;
    if (!isUninitialized && !subscription) {
      navigate(ROUTE_ROOT);
      return;
    }
    if (["active", "trialing"].includes(subscription?.status)) {
      navigate(ROUTE_ROOT);
      return;
    }

    if (!subscribed && !clientSecret && stripeCustomerId && authToken) {
      getClientSecret();
    }

    if (subscribed) {
      // Long polling subscription api
      setLoading(true);
      const timeoutId = setTimeout(() => {
        if (retryCount < 3) {
          refetch();
          setRetryCount((prev) => prev + 1);
        } else {
          setLoading(false);
        }
      }, 5000);

      if (retryCount === 3 && !clientSecret) {
        getClientSecret();
      }
      // Clean up the timeout on component unmount or effect re-run
      return () => clearTimeout(timeoutId);
    }
  }, [
    isSuccess,
    subscribed,
    subscription,
    clientSecret,
    stripeCustomerId,
    authToken,
    retryCount,
    getClientSecret,
    navigate,
    refetch,
    isUninitialized,
  ]);

  return (
    <div className="relative">
      <Header onLogoClick={() => navigate(ROUTE_PLANS)} />
      {loading && (
        <div className="flex flex-col gap-2 items-center justify-center mt-24">
          <LoadingSpinner size="32px" />
        </div>
      )}
      {!loading && (
        <div>
          <div className="text-base md:text-xl text-center my-8 px-4">
            Choose the plan that best fits your team and goals.
          </div>
          <stripe-pricing-table
            pricing-table-id={STRIPE_RENEW_PRICING_TABLE_ID}
            publishable-key={STRIPE_PRICING_TABLE_PUBLISHABLE_KEY}
            customer-session-client-secret={clientSecret}
          ></stripe-pricing-table>
        </div>
      )}
    </div>
  );
};

export default RenewPlans;
