import { useFormikContext } from "formik";
import React from "react";
import CustomSelect, {
  OptionType,
} from "../../../../../components/form/CustomSelect";
import { BrandVoiceEnum } from "../../../../../enums/template";
import { Template } from "../../../../../models";

const brandVoiceOptions: OptionType[] = [
  {
    value: null,
    label: "None",
    description: "",
  },
  {
    value: BrandVoiceEnum.PROFESSIONAL,
    label: "Professional",
    description: "Formal, polished, and business-like",
  },
  {
    value: BrandVoiceEnum.CASUAL,
    label: "Casual",
    description: "Informal, conversational, and relaxed",
  },
  {
    value: BrandVoiceEnum.ENTHUSIASTIC,
    label: "Enthusiastic",
    description: "Energetic, passionate, and upbeat",
  },
  {
    value: BrandVoiceEnum.STRAIGHTFORWARD,
    label: "Straightforward",
    description: "Direct, clear, and to-the-point",
  },
  {
    value: BrandVoiceEnum.WITTY,
    label: "Witty",
    description: "Clever, humorous, and sharp",
  },
  {
    value: BrandVoiceEnum.EMPOWERING,
    label: "Empowering",
    description: "Inspiring, motivating, and encouraging",
  },
  {
    value: BrandVoiceEnum.INTELLECTUAL,
    label: "Intellectual",
    description: "Sophisticated, academic, and well-informed",
  },
  {
    value: BrandVoiceEnum.MOTIVATIONAL,
    label: "Motivational",
    description:
      "Uplifting, aspirational, focuses on potential and bigger picture",
  },
];

export default function BrandVoiceSelector() {
  const { values, setFieldValue } = useFormikContext<Partial<Template>>();
  return (
    <div className="mb-6">
      <CustomSelect
        label="Brand Voice"
        placeholder="-"
        options={brandVoiceOptions}
        selected={brandVoiceOptions.find(
          (option) => option.value === values?.brand_voice_id
        )}
        tooltip="The distinct style or manner of communication that your brand uses consistently across all its messaging and content."
        setSelected={(option) => {
          setFieldValue(
            "brand_voice_id",
            (option?.value as BrandVoiceEnum) || null
          );
        }}
      />
    </div>
  );
}
