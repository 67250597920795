import classNames from "classnames";
import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import React, { useCallback } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import WordpressSVG from "../../../assets/svg/wordpress.svg";
import Button from "../../../components/form/Button";
import {
  ROUTE_DESTINATIONS_EDIT,
  ROUTE_TEMPLATES_EDIT,
} from "../../../constants/routes";
import { Destination } from "../../../models/destination";
import { formatDateUS } from "../../../utils";
import DestinationActionButtons from "./DestinationActionButtons";

export const headers = [
  { key: "name", label: "Name" },
  { key: "url", label: "URL" },
  { key: "created_at", label: "Created" },
  { key: "template_list", label: "Templates" },
  { key: "destination_id", label: "" },
];

const DestinationTable = ({
  destinations,
}: {
  destinations: Destination[];
}) => {
  const navigate = useNavigate();

  const renderDestinationData = useCallback(
    (key: string, destination: Destination) => {
      switch (key) {
        case "name":
          return (
            <div className="flex items-center gap-3">
              <img
                alt="wordpress-logo"
                src={WordpressSVG}
                className="size-5"
              />
              <div className="truncate overflow-hidden">
                {destination["name"]}
              </div>
            </div>
          );
        case "url":
          return (
            <div
              className="truncate overflow-hidden text-primary w-max"
              onClick={(e: any) => {
                e.stopPropagation();
                window.open(destination["destination"]["url"], "_blank");
              }}
            >
              {destination["destination"]["url"]}
            </div>
          );
        case "created_at":
          return (
            <div className="truncate overflow-hidden">
              {formatDateUS(destination["created_at"]!)}
            </div>
          );
        case "template_list":
          if (
            !destination["template_list"] ||
            destination["template_list"]?.length === 0
          ) {
            return <div className="text-center">-</div>;
          }
          return (
            <div className="text-center">
              <Dropdown
                overlay={
                  <Menu
                    onClick={(e) => {
                      e.domEvent.stopPropagation();
                      window.open(
                        generatePath(ROUTE_TEMPLATES_EDIT, { id: e.key }) +
                          "?redirect_to_step=destination",
                        "_blank"
                      );
                    }}
                    className="text-sm max-h-96 overflow-y-scroll no-scrollbar"
                    selectable={false}
                    style={{ padding: 4, minWidth: 150 }}
                  >
                    {destination["template_list"].map((option) => {
                      return (
                        <MenuItem
                          key={option.template_id}
                          className="hover:bg-gray-0 hover:text-primary flex gap-1 items-center justify-between"
                          style={{ padding: "6px 8px", cursor: "pointer" }}
                        >
                          <div className="flex gap-1 max-w-32 truncate">
                            {option.template_name}
                          </div>
                        </MenuItem>
                      );
                    })}
                  </Menu>
                }
                trigger={["click"]}
              >
                <Button
                  paddingZero
                  variant="light-primary"
                  className="p-0.5 w-8"
                  onClick={(e: any) => {
                    e.stopPropagation();
                  }}
                >
                  {destination["template_list"]?.length}
                </Button>
              </Dropdown>
            </div>
          );

        case "destination_id":
          return (
            <div className="flex justify-end">
              <DestinationActionButtons
                destinationId={destination["destination_id"]!}
              />
            </div>
          );
        default:
          return null;
      }
    },
    []
  );

  return (
    <table className="mt-6 min-w-full border-separate border-spacing-0 border-r border-l border-t overflow-x-auto">
      <thead>
        <tr>
          {headers.map(({ key, label, sub }: any) => {
            let width = "10%";
            let padding = "0.75rem";
            if (["name", "url"].includes(key)) {
              width = "35%";
            }
            return (
              <th
                key={key}
                scope="col"
                className={classNames(
                  "uppercase tracking-wide text-xs bg-gray-50 py-3 text-gray-500 border-b rounded-t-lg",
                  {
                    "text-center": ["template_list"].includes(key),
                  },
                  {
                    "text-start": !["template_list"].includes(key),
                  }
                )}
                style={{ width: width, padding: padding }}
              >
                {label}
                <div>{sub}</div>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {destinations.map((destination: Destination, index: number) => (
          <tr
            key={destination.destination_id}
            className="hover:bg-gray-100 cursor-pointer"
            onClick={() => {
              navigate(
                generatePath(ROUTE_DESTINATIONS_EDIT, {
                  id: destination.destination_id!,
                })
              );
            }}
          >
            {headers.map(({ key }: any) => {
              let width = "10%";
              let padding = "0.5rem 0.75rem";
              if (["name", "url"].includes(key)) {
                width = "35%";
              }
              return (
                <td
                  key={key}
                  className="py-2 text-sm text-gray-600 border-b"
                  style={{ width, padding, maxWidth: 200 }}
                >
                  {renderDestinationData(key, destination)}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DestinationTable;
