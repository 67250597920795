import { useFormikContext } from "formik";
import React from "react";
import TextArea from "../../../../../components/form/TextArea";
import { Template } from "../../../../../models";

export default function AdditionalContextInput() {
  const { values, setFieldValue } = useFormikContext<Partial<Template>>();
  return (
    <TextArea
      label="Additional context"
      name="speaker_about"
      value={values?.additional_context ?? ""}
      tooltip="Add any additional information about the source or channel which will be helpful to the AI for content generation."
      placeholder="example: Jane Doe is speaking in a podcast about the importance of mental health in fitness."
      maxLength={500}
      maxHeight={150}
      showCharacterCount={true}
      onChange={({ target: { value } }) =>
        setFieldValue("additional_context", value)
      }
      onBlur={({ target: { value } }) => {
        setFieldValue("additional_context", value.trim());
      }}
    />
  );
}
