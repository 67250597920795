import { useFormikContext } from "formik";
import React from "react";
import TextArea from "../../../../../components/form/TextArea";
import { SourceEnum } from "../../../../../enums/template";
import { Template } from "../../../../../models";
import {
  getMediaLinkPlaceholder,
  getMediaLinkTooltip,
} from "../../../../../utils";

export default function CustomTextInput() {
  const { values, errors, setFieldValue } =
    useFormikContext<Partial<Template>>();
  return (
    <TextArea
      label="Transcript"
      name="text-block"
      value={values?.text_block ?? ""}
      tooltip={getMediaLinkTooltip(SourceEnum.TEXT_BLOCK)}
      placeholder={getMediaLinkPlaceholder(SourceEnum.TEXT_BLOCK)}
      maxLength={10000}
      maxHeight={300}
      errorMessage={errors.text_block}
      errorPosition="bottom-left"
      showCharacterCount={true}
      onChange={({ target: { value } }) => setFieldValue("text_block", value)}
      onBlur={({ target: { value } }) => {
        setFieldValue("text_block", value.trim());
      }}
    />
  );
}
