import { PlusIcon, XMarkIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import InfoTooltip from "../../../../../components/common/InfoTooltip";
import TimezoneSelector from "../../../../../components/features/TimezoneSelector";
import Button from "../../../../../components/form/Button";
import Input from "../../../../../components/form/Input";
import {
  AutomationDays,
  AutomationDaysMap,
} from "../../../../../enums/template";
import { Template } from "../../../../../models";
import { getCurrentTime, sortNumbers } from "../../../../../utils";

function DaysSelector() {
  const { values, setFieldValue } = useFormikContext<Partial<Template>>();
  const autoDays = sortNumbers(values?.auto_days! || []);
  return (
    <div className="flex flex-col gap-3">
      <div className="block uppercase text-xs font-medium leading-6">
        Select the days to run the automation
      </div>
      <div className="flex gap-4">
        {AutomationDays.map((day) => (
          <button
            key={day}
            tabIndex={0}
            type="button"
            className={classNames(
              "text-xs rounded-full w-6 h-6 flex items-center justify-center border",
              {
                "border-primary/90 bg-primary/90 hover:bg-primary text-white cursor-pointer":
                  autoDays.includes(day),
              },
              {
                "border-gray-200 hover:bg-primary/10 text-gray-500 cursor-pointer":
                  !autoDays.includes(day),
              }
            )}
            onClick={() => {
              if (autoDays.includes(day)) {
                const updatedAutoDays = autoDays.filter((d) => d !== day);
                setFieldValue("auto_days", updatedAutoDays);
              } else {
                setFieldValue("auto_days", [...autoDays, day]);
              }
            }}
          >
            {AutomationDaysMap[day]}
          </button>
        ))}
      </div>
    </div>
  );
}

function TimesSelector() {
  const { values, setFieldValue } = useFormikContext<Partial<Template>>();
  const [showAutoTime2, setShowAutoTime2] = useState(
    values?.auto_time2 ?? false
  );
  return (
    <div className="flex flex-col">
      <div className="uppercase text-xs font-medium leading-6 flex items-center gap-2">
        Select times
        <InfoTooltip
          id="select_times"
          tooltip="At the selected times, the profile will be checked for new content to generate articles."
        />
      </div>
      <div className="grid gap-3 mt-1">
        <div className="max-w-[25rem]">
          <TimezoneSelector />
        </div>
        <div className="flex gap-6 w-full items-end">
          <div className="w-48">
            <Input
              label="Time 1"
              type="time"
              id="auto_time1"
              className="leading-none text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary block w-full"
              min="00:00"
              max="24:00"
              value={values?.auto_time1!}
              onChange={(e) => {
                setFieldValue("auto_time1", e.target.value);
              }}
              name={"auto_time1"}
            />
          </div>
          {!showAutoTime2 && (
            <Button
              variant="icon"
              type="button"
              onClick={() => {
                setShowAutoTime2(true);
                setFieldValue("auto_time2", getCurrentTime());
              }}
            >
              <PlusIcon className="size-5 text-primary" />
            </Button>
          )}
        </div>
        {showAutoTime2 && (
          <div className="flex gap-6 w-full items-end">
            <div className="w-48">
              <Input
                label="Time 2"
                type="time"
                name="auto_time2"
                id="auto_time2"
                className="leading-none text-gray-900 text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full"
                min="00:00"
                max="24:00"
                value={values?.auto_time2!}
                onChange={(e) => setFieldValue("auto_time2", e.target.value)}
              />
            </div>
            <Button
              variant="icon"
              type="button"
              onClick={() => {
                setFieldValue("auto_time2", null);
                setShowAutoTime2(false);
              }}
            >
              <XMarkIcon className="size-5 text-red-500" />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default function AutomationScheduleSettings() {
  return (
    <div className="w-full space-x-3">
      <div className="grid grid-cols-1 gap-8">
        <DaysSelector />
        <TimesSelector />
      </div>
    </div>
  );
}
