import { useFormikContext } from "formik";
import React from "react";
import CustomSelect, {
  OptionType,
} from "../../../../../components/form/CustomSelect";
import { ImageStyleEnum } from "../../../../../enums/template";
import { Template } from "../../../../../models";

const imageStyleOptions: OptionType[] = [
  {
    value: null,
    label: "None",
    description: "",
  },
  {
    value: ImageStyleEnum.MINIMALISTIC,
    label: "Minimalistic",
    description:
      "Simplified portrayal focusing on essential elements with clean lines",
  },
  {
    value: ImageStyleEnum.GRADIENT,
    label: "Gradient",
    description:
      "Smooth transitions between colors for a dynamic and engaging visual",
  },
  {
    value: ImageStyleEnum.REALISTIC,
    label: "Realistic",
    description:
      "Detailed depiction mimicking real-world objects and scenes with precision",
  },
  {
    value: ImageStyleEnum.ABSTRACT,
    label: "Abstract",
    description:
      "Non-representational interpretation featuring unconventional shapes and colors",
  },
  {
    value: ImageStyleEnum.SURREAL,
    label: "Surreal",
    description:
      "Dreamlike depiction defying conventional reality with unexpected elements",
  },
  {
    value: ImageStyleEnum.VINTAGE,
    label: "Vintage",
    description:
      "Nostalgic style evoking aesthetics from the past with faded colors",
  },
  {
    value: ImageStyleEnum.FUTURISTIC,
    label: "Futuristic",
    description:
      "Forward-looking portrayal featuring sleek and modern elements",
  },
  {
    value: ImageStyleEnum.IMPRESSIONISTIC,
    label: "Impressionistic",
    description: "Blurry and dappled brushstrokes",
  },
  {
    value: ImageStyleEnum.OIL_PAINTING,
    label: "Oil Painting",
    description: "Oil paint on canvas with bold and dramatic brush strokes",
  },
  {
    value: ImageStyleEnum.PIXEL_ART,
    label: "Pixel Art",
    description: "Blocky, pixelated graphics reminiscent of early video games",
  },
  {
    value: ImageStyleEnum.WATERCOLOR,
    label: "Watercolor",
    description:
      "Soft and fluid depiction created with transparent watercolor paints",
  },
  {
    value: ImageStyleEnum.POP_ART,
    label: "Pop Art",
    description: "Bold and vibrant style inspired by popular culture icons",
  },
  {
    value: ImageStyleEnum.CARTOON,
    label: "Cartoon",
    description:
      "Playful representation resembling illustrations from comics or cartoons",
  },
];

export default function ImageStyleSelector() {
  const { values, setFieldValue } = useFormikContext<Partial<Template>>();
  return (
    <CustomSelect
      label="Image style"
      placeholder="-"
      tooltip="The overall aesthetic or visual appearance of the image."
      options={imageStyleOptions}
      selected={imageStyleOptions.find(
        (option) => option.value === values?.image_style_id
      )}
      setSelected={(option) => {
        setFieldValue("image_style_id", option?.value || null);
      }}
    />
  );
}
