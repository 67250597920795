import { useFormikContext } from "formik";
import React from "react";
import InfoTooltip from "../../../../../components/common/InfoTooltip";
import RadioGroup, { Option } from "../../../../../components/form/RadioGroup";
import { TranscribeSubjectEnum } from "../../../../../enums/template";
import { Template } from "../../../../../models";

const transcribeSubjectOptions = [
  {
    id: TranscribeSubjectEnum.GENERAL,
    name: "General",
  },
  {
    id: TranscribeSubjectEnum.FINANCE,
    name: "Finance",
  },
  {
    id: TranscribeSubjectEnum.MEDICAL,
    name: "Medical",
  },
  {
    id: TranscribeSubjectEnum.AUTOMOTIVE,
    name: "Automotive",
  },
];

export default function TranscribeSubjectSelector() {
  const { values, setFieldValue } = useFormikContext<Partial<Template>>();
  return (
    <div className="w-max mb-6">
      <h3 className="mb-2 tracking-wide font-medium leading-6 text-gray-900 text-xs flex items-center gap-2 uppercase">
        Primary Subject of Your Content
        <InfoTooltip
          id="subject"
          tooltip="Use General for most cases. If your content is specific to finance, medical, or automotive then selecting the appropriate option will aid in the vocabulary translation."
        />
      </h3>
      <RadioGroup
        options={transcribeSubjectOptions}
        name="transcribe_subject_id"
        selected={values?.transcribe_subject_id}
        onClick={({ id }: Option) => setFieldValue("transcribe_subject_id", id)}
      />
    </div>
  );
}
