import { useFormikContext } from "formik";
import React from "react";
import InfoTooltip from "../../../../../components/common/InfoTooltip";
import RadioGroup, { Option } from "../../../../../components/form/RadioGroup";
import { FaqQuestionPrefixEnum } from "../../../../../enums/template";
import { Template } from "../../../../../models";

const faqPrefixOptions = [
  {
    id: FaqQuestionPrefixEnum.Q_LETTER,
    name: "Q",
    description: "Ex: Q: Your article question?",
  },
  // {
  //   id: FaqQuestionPrefixEnum.Q_MARK,
  //   name: "?",
  //   description: "Ex: ?: Your article question?",
  // },
  {
    id: null,
    name: "None",
    description: "Ex: Your article question?",
  },
];

export default function FaqFormatSelector() {
  const { values, setFieldValue } = useFormikContext<Partial<Template>>();
  return (
    <div className="mb-6">
      <h3 className="mb-2 tracking-wide font-medium leading-6 text-gray-900 text-xs flex items-center gap-2 uppercase">
        Select an FAQ format
        <InfoTooltip
          id="question_format"
          tooltip='For aesthetics, you can add a prefix of "Q:" to the questions of the FAQ.'
        />
      </h3>
      <RadioGroup
        options={faqPrefixOptions}
        name="faqPrefix"
        selected={values?.faq_question_prefix_id}
        onClick={({ id }: Option) =>
          setFieldValue("faq_question_prefix_id", id)
        }
      />
    </div>
  );
}
