import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import Button from "../../../components/form/Button";
import { ROUTE_DESTINATIONS_EDIT } from "../../../constants/routes";
import DestinationDeleteModal from "./DestinationDeleteModal";

const DestinationActionButtons = ({
  destinationId,
}: {
  destinationId: string;
}) => {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  return (
    <>
      <div className="flex items-center gap-1">
        <Button
          paddingZero
          variant="light-primary"
          className="gap-1 flex items-center p-1"
          onClick={() =>
            navigate(
              generatePath(ROUTE_DESTINATIONS_EDIT, {
                id: destinationId,
              })
            )
          }
        >
          <PencilSquareIcon className="size-4" />
          <span className="md:hidden">Edit</span>
        </Button>
        <div className="h-4 w-px bg-gray-200" />
        <Button
          paddingZero
          variant="light-danger"
          tooltip="Delete wordpress connection"
          className="gap-1 flex items-center p-1"
          onClick={(e: any) => {
            e.stopPropagation();
            setShowDeleteModal(true);
          }}
        >
          <TrashIcon className="size-4" />
          <span className="md:hidden">Delete</span>
        </Button>
      </div>
      {showDeleteModal && (
        <DestinationDeleteModal
          destinationId={destinationId}
          onRequestClose={() => {
            setShowDeleteModal(false);
          }}
        />
      )}
    </>
  );
};

export default DestinationActionButtons;
