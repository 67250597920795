import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import WordpressSVG from "../../../../assets/svg/wordpress.svg";
import RefreshButton from "../../../../components/common/RefreshButton";
import Button from "../../../../components/form/Button";
import SelectV2, { OptionType } from "../../../../components/form/CustomSelect";
import { ROUTE_DESTINATIONS_CREATE } from "../../../../constants/routes";
import useSelectedAccount from "../../../../hooks/useSelectedAccount";
import { Template } from "../../../../models";
import { Destination } from "../../../../models/destination";
import { useGetDestinationsQuery } from "../../../../redux/services/destinations";
import TemplateContainer from "../common/TemplateContainer";

const DestinationSelector = () => {
  const { values, setFieldValue } = useFormikContext<Partial<Template>>();
  const { accountId } = useSelectedAccount();

  const [destinations, setDestinations] = useState<OptionType[]>([]);

  // Fetch data with pagination
  const { data, refetch, isFetching } = useGetDestinationsQuery(
    {
      account_id: accountId || "",
      offset: 0,
      limit: 25,
      include_total_count: true,
    },
    { skip: !accountId }
  );

  useEffect(() => {
    if (data?.data) {
      const newDestinations = data.data.map((destination: Destination) => ({
        value: destination.destination_id,
        label: destination.name,
        icon: WordpressSVG,
      }));

      setDestinations([...newDestinations]);
    }
  }, [data]);

  return (
    <div className="animate-fadeIn">
      <TemplateContainer className="flex flex-col gap-4">
        <div className="flex gap-2 items-center">
          <SelectV2
            label="Destination"
            placeholder="-"
            options={destinations || []}
            selected={destinations.find(
              (option) => option.value === values?.destination_id
            )}
            setSelected={(option) => {
              setFieldValue("destination_id", option?.value || null);
            }}
            className="w-full"
          />
          <RefreshButton
            className="mt-6"
            refetch={refetch}
            loading={isFetching}
            tooltip="Refresh destination list."
          />
        </div>
        <Button
          paddingZero
          variant="transparent-primary"
          className="flex items-center gap-1 w-max"
          onClick={() => {
            window.open(ROUTE_DESTINATIONS_CREATE, "_blank");
          }}
        >
          Add new destination <ArrowTopRightOnSquareIcon className="size-4" />
        </Button>
      </TemplateContainer>
    </div>
  );
};

export default DestinationSelector;
