import classNames from "classnames";
import React from "react";
import InfoTooltip from "../common/InfoTooltip";

export type Option = {
  id: any;
  name: any;
  description?: string;
};

type RadioGroupProps = {
  options: Option[];
  name: string;
  selected?: any;
  disabled?: boolean;
  label?: string;
  tooltip?: string;
  className?: string;
  onClick: (option: Option) => void;
};

const RadioGroup = ({
  options,
  name,
  selected,
  disabled,
  label,
  tooltip,
  className,
  onClick,
}: RadioGroupProps) => {
  return (
    <fieldset className={classNames("", className)}>
      {label && (
        <h3 className="mb-1 tracking-wide font-medium leading-6 text-gray-900 text-xs flex items-center gap-2 uppercase">
          {label}
          {tooltip && (
            <InfoTooltip
              id={`${name}-radio-label`}
              tooltip={tooltip}
            />
          )}
        </h3>
      )}

      <div className="flex flex-col md:flex-row gap-2 md:gap-5 md:items-center">
        {options.map((option: Option) => {
          const isSelected = selected === option.id;
          const optionId = `${name}-${option.id}`;
          return (
            <label
              key={option.id}
              htmlFor={optionId}
              className="relative flex items-start cursor-pointer disabled:cursor-not-allowed"
            >
              <div className="flex h-6 items-center">
                <input
                  id={optionId}
                  aria-describedby={
                    option.description ? `${optionId}-description` : undefined
                  }
                  name={name}
                  type="radio"
                  disabled={disabled}
                  checked={isSelected}
                  onChange={() => {
                    if (!disabled) {
                      onClick(option);
                    }
                  }}
                  className={classNames(
                    "relative size-4 appearance-none rounded-full border border-gray-300 bg-white",
                    "before:absolute before:inset-1 before:rounded-full before:bg-white hover:bg-primary/10 hover:border-primary/10",
                    "checked:border-primary checked:bg-primary checked:hover:bg-primary checked:disabled:hover:bg-gray-100 checked:disabled:hover:border-gray-300",
                    "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary",
                    "disabled:cursor-not-allowed disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400",
                    "forced-colors:appearance-auto forced-colors:before:hidden [&:not(:checked)]:before:hidden"
                  )}
                />
              </div>
              <div className="ml-2 text-sm leading-6">
                <span className="text-gray-800 whitespace-nowrap cursor-pointer disabled:cursor-not-allowed">
                  {option.name}
                </span>
                {option.description && (
                  <p
                    id={`${optionId}-description`}
                    className="text-gray-500 text-xs cursor-pointer disabled:cursor-not-allowed m-0"
                  >
                    {option.description}
                  </p>
                )}
              </div>
            </label>
          );
        })}
      </div>
    </fieldset>
  );
};

export default RadioGroup;
