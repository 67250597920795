import classNames from "classnames";
import React from "react";
import InfoTooltip from "../../../../components/common/InfoTooltip";
import Switch from "../../../../components/form/Switch";

const SwitchContainer = ({
  title,
  subTitle,
  className,
  tooltip,
  disabled,
  enabled,
  setEnabled,
}: {
  title: string;
  subTitle?: string;
  className?: string;
  tooltip?: string;
  disabled?: boolean;
  enabled: boolean;
  setEnabled: (enabled: boolean) => void;
}) => {
  return (
    <div
      className={classNames(
        "flex space-x-3",
        { "items-start": subTitle },
        { "items-center": !subTitle },
        className
      )}
    >
      <Switch
        label={title}
        disabled={disabled}
        enabled={enabled}
        setEnabled={setEnabled}
      />
      <div>
        <div className="flex items-center gap-x-2">
          <label className="text-sm flex items-center gap-2">
            {title}
            {tooltip && (
              <InfoTooltip
                id={title}
                tooltip={tooltip}
              />
            )}
          </label>
        </div>
        {subTitle && (
          <div
            className="text-gray-600 text-sm leading-normal"
            data-testid="undefined-description"
          >
            {subTitle}
          </div>
        )}
      </div>
    </div>
  );
};

export default SwitchContainer;
