import classNames from "classnames";
import React from "react";
const ErrorMessage = ({
  errorMessage,
  name,
  className,
}: {
  errorMessage: string;
  name: string;
  className?: string;
}) => (
  <span
    className={classNames(
      "inline-flex text-2xs tracking-wide font-medium leading-6 text-red-500 uppercase",
      className
    )}
    id={`${name}-error`}
  >
    {errorMessage}
  </span>
);

export default ErrorMessage;
