import { useFormikContext } from "formik";
import React from "react";
import { SourceEnum } from "../../../../../enums/template";
import { Template } from "../../../../../models";
import CustomTextInput from "./CustomTextInput";
import { default as MediaAndAutomationSettings } from "./MediaAndAutomationSettings";
import RssFeedUrlsInput from "./RssFeedUrlsInput";
import UploadMediaInput from "./UploadMediaInput";

const SOCIAL_MEDIA_PLATFORMS = [
  SourceEnum.INSTAGRAM_REELS,
  SourceEnum.YOUTUBE_SHORTS,
  SourceEnum.YOUTUBE_VIDEOS,
  SourceEnum.APPLE_PODCASTS,
];

export default function MediaSetup() {
  const { values } = useFormikContext<Partial<Template>>();

  if (!values?.source_id) return null;

  return (
    <div>
      {SOCIAL_MEDIA_PLATFORMS.includes(values.source_id) && (
        <MediaAndAutomationSettings />
      )}

      {values.source_id === SourceEnum.RSS_FEEDS && <RssFeedUrlsInput />}
      {values.source_id === SourceEnum.TEXT_BLOCK && <CustomTextInput />}
      {values.source_id === SourceEnum.UPLOAD_MEDIA && <UploadMediaInput />}
    </div>
  );
}
