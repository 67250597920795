import { useFormikContext } from "formik";
import React from "react";
import { Template } from "../../../../../models";
import SwitchContainer from "../../common/SwitchContainer";

export default function ImageExcludeFacesToggle() {
  const { values, setFieldValue } = useFormikContext<Partial<Template>>();
  return (
    <SwitchContainer
      title="Attempt to exclude people and faces"
      enabled={values.exclude_image_faces || false}
      setEnabled={() => {
        setFieldValue("exclude_image_faces", !values?.exclude_image_faces);
      }}
    />
  );
}
