import { ChevronDownIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { useFormikContext } from "formik";
import React, { KeyboardEvent, useEffect, useRef, useState } from "react";
import InfoTooltip from "../../../../components/common/InfoTooltip";
import { ArticleTypeEnum } from "../../../../enums/template";
import { Template } from "../../../../models";

export interface Option {
  type: ArticleTypeEnum;
  description: string;
  targetWordCount: string;
}

const articleTypes: Option[] = [
  {
    type: ArticleTypeEnum.STANDARD_ARTICLE,
    description:
      "Covers a topic with context, examples, and supporting details.",
    targetWordCount: "1000-2000",
  },
  {
    type: ArticleTypeEnum.SHORT_SUMMARY,
    description:
      "Condenses the main points of a topic for quick understanding.",
    targetWordCount: "400-800",
  },
  {
    type: ArticleTypeEnum.LONG_SUMMARY,
    description:
      "Detailed coverage of a topic providing key insights and essential points.",
    targetWordCount: "2000-4000",
  },
  {
    type: ArticleTypeEnum.NEWS,
    description:
      "Reports on recent developments with clear, factual information.",
    targetWordCount: "400-800",
  },
  {
    type: ArticleTypeEnum.LISTICLE,
    description:
      "Information presented as an easy-to-read bulleted or numbered list.",
    targetWordCount: "1500-4000",
  },
  {
    type: ArticleTypeEnum.TUTORIAL,
    description: "Step-by-step guide for completing a task or skill.",
    targetWordCount: "1500-4000",
  },
  {
    type: ArticleTypeEnum.OPINION,
    description: "Personal perspective on a topic with unique insights.",
    targetWordCount: "500-800",
  },
  {
    type: ArticleTypeEnum.INTERVIEW,
    description:
      "Conversation with an expert or influencer, sharing their insights.",
    targetWordCount: "1000-3000",
  },
  {
    type: ArticleTypeEnum.ACTION_STEPS,
    description: "Specific steps readers can take to achieve a result.",
    targetWordCount: "1000-3000",
  },
  {
    type: ArticleTypeEnum.PERSONAL_STORIES,
    description: "Brief, personal story or journey illustrating a key point.",
    targetWordCount: "800-2000",
  },
  {
    type: ArticleTypeEnum.PRODUCT_REVIEW,
    description: "Evaluation of a product's features and overall value.",
    targetWordCount: "1500-4000",
  },
  {
    type: ArticleTypeEnum.GUIDE,
    description: "In-depth, comprehensive resource on a specific topic.",
    targetWordCount: "4000-10000",
  },
  {
    type: ArticleTypeEnum.CASE_STUDY,
    description: "Real-world example showing a solution and its results.",
    targetWordCount: "2000-4000",
  },
];

const articleTypesMap = {
  [ArticleTypeEnum.STANDARD_ARTICLE]: "Standard Article",
  [ArticleTypeEnum.SHORT_SUMMARY]: "Short Summary",
  [ArticleTypeEnum.LONG_SUMMARY]: "Long Summary",
  [ArticleTypeEnum.NEWS]: "News",
  [ArticleTypeEnum.LISTICLE]: "Listicle",
  [ArticleTypeEnum.TUTORIAL]: "Tutorial",
  [ArticleTypeEnum.OPINION]: "Opinion",
  [ArticleTypeEnum.INTERVIEW]: "Interview",
  [ArticleTypeEnum.ACTION_STEPS]: "Action Steps",
  [ArticleTypeEnum.PERSONAL_STORIES]: "Personal Stories",
  [ArticleTypeEnum.PRODUCT_REVIEW]: "Product Review",
  [ArticleTypeEnum.GUIDE]: "Guide",
  [ArticleTypeEnum.CASE_STUDY]: "Case Study",
};

const ArticleType = () => {
  const { values, setFieldValue } = useFormikContext<Partial<Template>>();
  const selectedArticleType: ArticleTypeEnum =
    values?.article_type_id ?? ArticleTypeEnum.STANDARD_ARTICLE;

  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownButtonRef = useRef<HTMLButtonElement>(null);
  const optionRefs = useRef<HTMLTableRowElement[]>([]);

  // Memoized handler to avoid recreating on every render
  const handleSelect = (type: ArticleTypeEnum) => {
    setIsOpen(false);
    setFieldValue("article_type_id", type || ArticleTypeEnum.STANDARD_ARTICLE);
    // Focus back on the dropdown button after selection
    setTimeout(() => dropdownButtonRef.current?.focus(), 0);
  };

  // Initialize refs array for option elements
  useEffect(() => {
    optionRefs.current = optionRefs.current.slice(0, articleTypes.length);
  }, []);

  // Handle outside click
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Check if dropdownRef is defined and if the click was outside
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
        setActiveIndex(-1);
      }
    };

    // Add event listener if dropdown is open
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  // Reset active index when dropdown opens/closes
  useEffect(() => {
    if (!isOpen) {
      setActiveIndex(-1);
    } else {
      // Set initial active item to the currently selected type when dropdown opens
      const currentIndex = articleTypes.findIndex(
        (item) => item.type === selectedArticleType
      );
      setActiveIndex(currentIndex >= 0 ? currentIndex : 0);
    }
  }, [isOpen, selectedArticleType]);

  // Focus on the active option when activeIndex changes
  useEffect(() => {
    if (isOpen && activeIndex >= 0 && activeIndex < optionRefs.current.length) {
      optionRefs.current[activeIndex]?.focus();
    }
  }, [activeIndex, isOpen]);

  // Handle keyboard navigation
  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (!isOpen) {
      // Open dropdown with down arrow or space or enter
      if (
        event.key === "ArrowDown" ||
        event.key === " " ||
        event.key === "Enter"
      ) {
        event.preventDefault();
        setIsOpen(true);
      }
      return;
    }

    switch (event.key) {
      case "Escape":
        // Close dropdown
        event.preventDefault();
        setIsOpen(false);
        dropdownButtonRef.current?.focus();
        break;
      case "ArrowDown":
        // Navigate to next option
        event.preventDefault();
        setActiveIndex((prevIndex) => {
          const nextIndex = (prevIndex + 1) % articleTypes.length;
          return nextIndex;
        });
        break;
      case "ArrowUp":
        // Navigate to previous option
        event.preventDefault();
        setActiveIndex((prevIndex) => {
          const nextIndex =
            prevIndex <= 0 ? articleTypes.length - 1 : prevIndex - 1;
          return nextIndex;
        });
        break;
      case "Home":
        // Go to first option
        event.preventDefault();
        setActiveIndex(0);
        break;
      case "End":
        // Go to last option
        event.preventDefault();
        setActiveIndex(articleTypes.length - 1);
        break;
      case "Enter":
      case " ":
        // Select current option
        event.preventDefault();
        if (activeIndex >= 0) {
          handleSelect(articleTypes[activeIndex].type);
        }
        break;
      case "Tab":
        // Close dropdown when tabbing out
        setIsOpen(false);
        break;
    }
  };

  return (
    <div>
      {/* Label and Tooltip */}
      <div className="mb-0.5 flex items-center">
        <label
          htmlFor="article_type_button"
          className="mb-1 flex items-center gap-2 uppercase text-xs tracking-wide font-medium leading-6 text-gray-900"
        >
          Article Type
          <InfoTooltip
            id="article_type"
            tooltip="Each article type uses distinct structures, tones, and objectives to enhance readability and achieve engagement."
          />
        </label>
      </div>

      <div
        className="relative"
        ref={dropdownRef}
        onKeyDown={handleKeyDown}
      >
        {/* Dropdown trigger */}
        <button
          id="article_type_button"
          ref={dropdownButtonRef}
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          aria-haspopup="listbox"
          aria-expanded={isOpen}
          aria-labelledby="article_type_label"
          className={classNames(
            "w-full flex items-center justify-between rounded-md px-2.5 py-2 bg-white text-sm text-left",
            "border-0 ring-1 ring-gray-300 hover:ring-gray-400 focus:ring-primary focus:outline-none",
            { "ring-primary": isOpen }
          )}
        >
          <span id="article_type_label">
            {articleTypesMap[selectedArticleType]}
          </span>
          <div className="flex">
            <ChevronDownIcon
              className={`h-5 w-5 text-gray-400 transition-transform ${isOpen ? "transform rotate-180" : ""}`}
              aria-hidden="true"
            />
          </div>
        </button>

        {/* Dropdown menu */}
        {isOpen && (
          <div
            className="absolute z-10 w-full mt-1 bg-white shadow-lg rounded-md border border-gray-200 overflow-hidden"
            role="listbox"
            aria-labelledby="article_type_label"
            tabIndex={-1}
          >
            <div className="max-h-72 overflow-y-auto">
              <table className="w-full text-sm text-left">
                <thead className="text-xs text-gray-700 uppercase bg-gray-100">
                  <tr>
                    <th className="px-4 py-3">Type</th>
                    <th className="px-4 py-3">Description</th>
                    <th className="px-4 py-3">Approx word count</th>
                  </tr>
                </thead>
                <tbody>
                  {articleTypes.map(
                    ({ type, description, targetWordCount }, index) => (
                      <tr
                        key={type}
                        ref={(el) => {
                          if (el) optionRefs.current[index] = el;
                        }}
                        onClick={() => handleSelect(type)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" || e.key === " ") {
                            e.preventDefault();
                            handleSelect(type);
                          }
                        }}
                        role="option"
                        aria-selected={selectedArticleType === type}
                        tabIndex={activeIndex === index ? 0 : -1}
                        className={classNames(
                          "border-b cursor-pointer outline-none",
                          {
                            "bg-gray-200 hover:bg-gray-200":
                              selectedArticleType === type ||
                              activeIndex === index,
                          },
                          {
                            "hover:bg-gray-50":
                              selectedArticleType !== type &&
                              activeIndex !== index,
                          }
                        )}
                      >
                        <td className="px-4 py-3 font-medium">
                          {articleTypesMap[type]}
                        </td>
                        <td className="px-4 py-3 text-gray-600">
                          {description}
                        </td>
                        <td className="px-4 py-3 text-gray-600">
                          {targetWordCount}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ArticleType;
