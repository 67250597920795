import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useSearchParam } from "react-use";
import { ROUTE_TEMPLATES } from "../../../constants/routes";
import {
  SourceEnum,
  TemplateStatusEnum,
  TemplateStepEnum,
} from "../../../enums/template";
import { useTokensFinished } from "../../../hooks/useTokensFinished";
import { Template } from "../../../models";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useGetDashboardQuery } from "../../../redux/services/dashboard";
import {
  useCreateTemplateMutation,
  useGetTemplateByIdQuery,
  useUpdateTemplateByIdMutation,
} from "../../../redux/services/templates";
import { useGetUserQuery } from "../../../redux/services/user";
import { setUpgradeModal } from "../../../redux/slices/userSlice";
import { RootState } from "../../../redux/store";
import { browserTimeZone } from "../../../utils";
import { generateRequestPayload } from "../utils/apiPayloadUtils";
import {
  validateCustomText,
  validateMediaLink,
  validateRSSFeeds,
  validateRequiredField,
} from "../utils/formValidationUtils";

export const useTemplateForm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isTokensFinished } = useTokensFinished();

  let { id: currentTemplateId } = useParams();
  const redirectStepParam = useSearchParam("redirect_to_step");

  const [currentStep, setCurrentStep] = useState(TemplateStepEnum.SETUP);
  const [template, setTemplate] = useState<Template | null>(null);

  const selectedAccount = useAppSelector(
    (state: RootState) => state.user.selectedAccount
  );

  const { data: userData } = useGetUserQuery(); // user api

  const { data: templateAPIResponse, refetch: getTemplateById } =
    useGetTemplateByIdQuery(
      {
        account_id: selectedAccount?.account_id,
        template_id: currentTemplateId,
      },
      { skip: !currentTemplateId }
    ); // template api

  const { data: dashboardData } = useGetDashboardQuery(
    {
      account_id: selectedAccount?.account_id ?? "",
      timezone: selectedAccount?.timezone ?? browserTimeZone,
    },
    { skip: !selectedAccount?.account_id }
  ); // dashboard data api

  const [
    createTemplate,
    {
      isLoading: isCreatingTemplate,
      isSuccess: isCreateSuccess,
      isError: isCreateError,
      error: createError,
    },
  ] = useCreateTemplateMutation(); // create template api

  const [
    updateTemplateById,
    {
      isLoading: isUpdatingTemplate,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      error: updateError,
    },
  ] = useUpdateTemplateByIdMutation(); // update template api

  useEffect(() => {
    // redirecting from destination screen
    if (
      redirectStepParam &&
      redirectStepParam === TemplateStepEnum.DESTINATION
    ) {
      setCurrentStep(TemplateStepEnum.DESTINATION);
    }
  }, [redirectStepParam]);

  useEffect(() => {
    // fetch template data on edit
    if (currentTemplateId) {
      getTemplateById();
    }
  }, [currentTemplateId, getTemplateById]);

  useEffect(() => {
    // initialize template form
    if (!selectedAccount?.account_id) return;

    if (templateAPIResponse) {
      const {
        destination_id,
        destination_id_list,
        source_id,
        media_url,
        rss_feed_urls,
      } = templateAPIResponse;
      let formValues = {
        ...templateAPIResponse,
        account_id: selectedAccount?.account_id,
        destination_id: destination_id || destination_id_list?.[0],
        rss_feed_urls: rss_feed_urls?.replaceAll(", ", "\n"),
      };

      if (source_id === SourceEnum.UPLOAD_MEDIA) {
        formValues = {
          ...formValues,
          upload_media_url: media_url,
          media_url: null,
        };
      }
      setTemplate(new Template(formValues));
    } else {
      const initialFormValues = new Template({
        source_id: SourceEnum.INSTAGRAM_REELS,
        include_web_embed: true,
        include_intro: true,
        account_id: selectedAccount?.account_id,
        bio: userData?.bio || `${userData?.first_name} ${userData?.last_name}`,
        timezone: selectedAccount?.timezone ?? browserTimeZone,
      });
      setTemplate(initialFormValues);
    }
  }, [templateAPIResponse, userData, selectedAccount]);

  useEffect(() => {
    // on template create / edit, success / error
    if (isCreateSuccess || isUpdateSuccess) {
      toast.success(
        `Successfully ${isCreateSuccess ? "created" : "updated"} template.`
      );
      navigate(ROUTE_TEMPLATES);
    } else if (isCreateError || isUpdateError || createError || updateError) {
      toast.error(
        ((createError || updateError) as any)?.data?.detail ||
          "Something went wrong! Please try again."
      );
    }
  }, [
    isCreateSuccess,
    isUpdateSuccess,
    isCreateError,
    isUpdateError,
    createError,
    updateError,
    navigate,
  ]);

  const handleValidation = (values: Template) => {
    const errors: any = {};

    errors.name = validateRequiredField(values.name, "Name");
    if (
      values.is_auto_enabled &&
      [
        SourceEnum.INSTAGRAM_REELS,
        SourceEnum.YOUTUBE_SHORTS,
        SourceEnum.YOUTUBE_VIDEOS,
        SourceEnum.APPLE_PODCASTS,
      ].includes(values?.source_id)
    ) {
      errors.username = validateRequiredField(values.username, "Username");
    }

    switch (values.source_id) {
      case SourceEnum.UPLOAD_MEDIA:
        errors.upload_media_url = validateRequiredField(
          values.upload_media_url,
          "Audio or video"
        );
        break;
      case SourceEnum.RSS_FEEDS:
        errors.rss_feed_urls = validateRSSFeeds(values.rss_feed_urls);
        break;
      case SourceEnum.TEXT_BLOCK:
        errors.text_block = validateCustomText(values.text_block);
        break;
      case SourceEnum.INSTAGRAM_REELS:
      case SourceEnum.YOUTUBE_VIDEOS:
      case SourceEnum.YOUTUBE_SHORTS:
      case SourceEnum.APPLE_PODCASTS:
        if (!values.is_auto_enabled) {
          errors.media_url = validateMediaLink(
            values.media_url,
            values.source_id
          );
        }
        break;
    }

    return Object.fromEntries(
      Object.entries(errors).filter(([_, v]) => v !== undefined)
    );
  };

  const handleSubmit = async (values: Template) => {
    if (values?.template_status_id === TemplateStatusEnum.QUEUED) return;

    if (isTokensFinished) {
      dispatch(setUpgradeModal(true));
      return;
    }

    const requestPayload = generateRequestPayload(values);

    try {
      if (currentTemplateId) {
        await updateTemplateById({
          ...requestPayload,
          account_id: selectedAccount?.account_id,
        })
          .unwrap()
          .catch(() => {});
      } else {
        await createTemplate({
          ...requestPayload,
          account_id: selectedAccount?.account_id,
        })
          .unwrap()
          .catch(() => {});
      }
    } catch (error) {
      // Error handling is already done in useEffect
    }
  };

  return {
    // State
    currentStep,
    setCurrentStep,
    template,
    selectedAccount,

    // API data
    dashboardData,

    // Form handlers
    handleValidation,
    handleSubmit,

    // Status flags
    isLoading: isCreatingTemplate || isUpdatingTemplate,
    isCreate: !currentTemplateId,
  };
};
