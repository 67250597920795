import React from "react";
import { Toaster } from "react-hot-toast";
import Modal from "react-modal";

import "../../index.css";
import UpgradeModal from "../features/UpgradeModal";
import AppRouter from "../routing/AppRouter";

Modal.setAppElement("#root");
const App = () => {
  return (
    <div className="h-full">
      <AppRouter />
      <UpgradeModal />
      {/* Notification Toasts Wrapper */}
      <Toaster />
    </div>
  );
};

export default App;
