import { Form, Formik, FormikProps } from "formik";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import Message from "../../components/common/Message";
import Modal from "../../components/common/Modal";
import { Heading } from "../../components/common/Typography";
import TimezoneSelector from "../../components/features/TimezoneSelector";
import Button from "../../components/form/Button";
import Input from "../../components/form/Input";
import { Account } from "../../models";
import { useUpdateAccountByIdMutation } from "../../redux/services/account";

const EditAccount = ({
  account,
  errorMessage,
  loading,
  onRequestClose,
}: {
  account: Account;
  errorMessage: string;
  loading: boolean;
  onRequestClose: () => void;
}) => {
  const [updateAccountById, { isLoading, isSuccess, isError, error }] =
    useUpdateAccountByIdMutation();

  useEffect(() => {
    if (isSuccess) {
      onRequestClose();
      toast.success("Updated");
    }
  }, [isSuccess, onRequestClose]);

  useEffect(() => {
    if (isError) {
      toast.error((error as any)?.data?.detail || "Failed to update");
    }
  }, [isError, error]);

  const handleSave = async (values: Partial<Account>) => {
    await updateAccountById(values)
      .unwrap()
      .catch(() => {});
  };

  return (
    <Modal
      onRequestClose={onRequestClose}
      width={500}
      isOpen={true}
      disableScroll
      containerClassName="overflow-visible"
    >
      <div className="py-8 px-8 h-auto">
        <Heading className="mb-4">Edit Account</Heading>
        <Formik
          initialValues={{
            account_id: account?.account_id,
            role_id: account?.role_id,
            company_name: account?.company_name,
            company_logo_url: account?.company_logo_url,
            timezone: account?.timezone,
            country_code: account?.country_code,
          }}
          onSubmit={(values) => handleSave(values)}
        >
          {(props: FormikProps<any>) => {
            const { values, setFieldValue } = props;
            return (
              <>
                <Message
                  type="error"
                  message={errorMessage}
                  className="justify-center"
                />
                <Form className="space-y-6 relative">
                  <Input
                    id="company_name"
                    label="Company name"
                    name="company_name"
                    type="text"
                    value={values.company_name}
                    // errorMessage={errors?.company_name || ""}
                    onChange={(e) =>
                      setFieldValue("company_name", e.target.value)
                    }
                  />
                  <TimezoneSelector />
                  <div className="flex gap-4 w-full">
                    <Button
                      variant="primary"
                      type="submit"
                      onClick={() => {}}
                      // loading={isLoading}
                    >
                      Update
                    </Button>
                    <Button
                      variant="transparent"
                      loading={isLoading}
                      onClick={onRequestClose}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};
export default EditAccount;
