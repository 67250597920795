import { useFormikContext } from "formik";
import React from "react";
import Input from "../../../../../components/form/Input";
import { Template } from "../../../../../models";
import {
  getMediaLinkPlaceholder,
  getMediaLinkTooltip,
  identifyUrlSource,
} from "../../../../../utils";

export default function MediaLinkInput() {
  const { values, errors, setFieldValue } =
    useFormikContext<Partial<Template>>();
  return (
    <div className="relative">
      <Input
        label="Media link"
        name="media_url"
        value={values?.media_url ?? ""}
        errorMessage={errors?.media_url as string}
        errorPosition="bottom-left"
        placeholder={getMediaLinkPlaceholder(values.source_id!)}
        tooltip={getMediaLinkTooltip(values.source_id!)}
        onChange={({ target: { value } }) => {
          setFieldValue("media_url", value.trim());
        }}
        onBlur={({ target: { value } }) => {
          const url = value.trim();
          if (url) {
            const source_id = identifyUrlSource(url);
            setFieldValue("source_id", source_id);
          }
        }}
      />
    </div>
  );
}
