import { useFormikContext } from "formik";
import React from "react";
import CustomSelect, {
  OptionType,
} from "../../../../../components/form/CustomSelect";
import { BrandToneEnum } from "../../../../../enums/template";
import { Template } from "../../../../../models";

const brandToneOptions: OptionType[] = [
  {
    value: null,
    label: "None",
    description: "",
  },
  {
    value: BrandToneEnum.INFORMATIVE,
    label: "Informative",
    description: "Educational, instructive, and explanatory",
  },
  {
    value: BrandToneEnum.PERSUASIVE,
    label: "Persuasive",
    description: "Convincing, compelling, and influential",
  },
  {
    value: BrandToneEnum.PLAYFUL,
    label: "Playful",
    description: "Light-hearted, whimsical, and amusing",
  },
  {
    value: BrandToneEnum.FRIENDLY,
    label: "Friendly",
    description: "Amiable, warm, cordial, and welcoming",
  },
  {
    value: BrandToneEnum.INSPIRING,
    label: "Inspiring",
    description: "Stimulating, thought-provoking, and interesting",
  },
  {
    value: BrandToneEnum.ASPIRATIONAL,
    label: "Aspirational",
    description: "Ambitious, goal-oriented, and uplifting",
  },
  {
    value: BrandToneEnum.PASSIONATE,
    label: "Passionate",
    description: "Enthusiastic, High-Energy, and inspiring a sense of momentum",
  },
];

export default function BrandToneSelector() {
  const { values, setFieldValue } = useFormikContext<Partial<Template>>();
  return (
    <CustomSelect
      label="Brand Tone"
      placeholder="-"
      options={brandToneOptions}
      selected={brandToneOptions.find(
        (option) => option.value === values?.brand_tone_id
      )}
      tooltip="The attitude or emotional flavor applied to the language used in communication. This may vary depending on the context."
      setSelected={(option) => {
        setFieldValue(
          "brand_tone_id",
          (option?.value as BrandToneEnum) || null
        );
      }}
    />
  );
}
