import { useFormikContext } from "formik";
import React from "react";
import useUser from "../../../../../hooks/useUser";
import { Template } from "../../../../../models";
import TemplateContainer from "../../common/TemplateContainer";
import ImageEngineSelector from "./ImageEngineSelector";
import ImageSizeSelector from "./ImageSizeSelector";
import ImageStyleSelector from "./ImageStyleSelector";
import ImageToneSelector from "./ImageToneSelector";

const ImageSettings = () => {
  const { user } = useUser();
  const { values } = useFormikContext<Partial<Template>>();
  const includeImage = !!values?.include_image;

  if (!includeImage) return null;

  return (
    <div className="animate-fadeIn">
      <TemplateContainer>
        <div className="space-y-6">
          {user?.is_super && <ImageEngineSelector />}
          <ImageSizeSelector />
          <ImageStyleSelector />
          <ImageToneSelector />
        </div>
      </TemplateContainer>
    </div>
  );
};

export default ImageSettings;
