import {
  ArrowLongRightIcon,
  ClipboardDocumentListIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { useNavigate } from "react-router-dom";
import InstagramBWSVG from "../../assets/svg/instagram-bw.svg";
import { ROUTE_TEMPLATES_CREATE } from "../../constants/routes";
import { useTokensFinished } from "../../hooks/useTokensFinished";
import { useAppDispatch } from "../../redux/hooks";
import { setUpgradeModal } from "../../redux/slices/userSlice";
import Button from "../form/Button";

const GetStartedTemplateBanner = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isTokensFinished } = useTokensFinished();

  return (
    <div className="max-w-full">
      <div
        data-testid="empty-screen"
        className="flex w-full select-none flex-col items-center justify-center rounded-lg p-7 lg:p-20 border-subtle border border-dashed mb-16"
      >
        <div className="flex items-center gap-4">
          <div className="bg-gray-50 flex h-[72px] w-[72px] items-center justify-center rounded-full">
            <img
              alt="instagram-bw"
              src={InstagramBWSVG}
              className="size-10"
            />
          </div>
          <ArrowLongRightIcon className="size-8" />

          <div className="bg-gray-50 flex h-[72px] w-[72px] items-center justify-center rounded-full">
            <ClipboardDocumentListIcon className="size-8" />
          </div>
        </div>
        <div className="flex max-w-[420px] flex-col items-center">
          <h2 className="font-cal text-gray-900 text-center text-xl mt-6">
            Create your first article.
          </h2>
          <div className="text-default mb-8 mt-3 text-center text-sm font-normal leading-6">
            Discover the magic of turning your Instagram Reels into captivating
            articles! Just provide the link to your Reel, and watch as we craft
            a unique and engaging article that captures the essence of your
            content.
          </div>
          <Button
            variant="primary"
            onClick={() => {
              if (isTokensFinished) {
                dispatch(setUpgradeModal(true));
              } else {
                navigate(ROUTE_TEMPLATES_CREATE);
              }
            }}
            className="px-3"
          >
            Create
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GetStartedTemplateBanner;
