import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import Avatar from "../../components/common/Avatar";
import PageHeading from "../../components/common/PageHeading";
import MediaUploader from "../../components/features/MediaUploader";
import Button from "../../components/form/Button";
import Input from "../../components/form/Input";
import { User as UserM } from "../../models";
import { useAppSelector } from "../../redux/hooks";
import {
  useGetUserQuery,
  useUpdateUserMutation,
} from "../../redux/services/user";
import { RootState } from "../../redux/store";
import { uploadAvatar } from "../../utils";
import TemplateContainer from "../TemplateForm/components/common/TemplateContainer";

const schema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
});

const User = () => {
  const authToken = useAppSelector((state: RootState) => state.auth.token);
  const { data: user, isLoading: loading } = useGetUserQuery();
  const [
    updateUser,
    {
      isLoading: isLoadingUpadte,
      isSuccess: isSuccessUpdate,
      isError: isErrorUpdate,
      error: errorUpdate,
      data: updateResponse,
    },
  ] = useUpdateUserMutation();

  const [editPhoto, setEditPhoto] = useState(false);

  useEffect(() => {
    if (isSuccessUpdate && updateResponse?.user_id) {
      toast.success("User profile updated.");
    }
  }, [isSuccessUpdate, updateResponse]);

  useEffect(() => {
    if (isErrorUpdate) {
      toast.error((errorUpdate as any)?.data?.detail || "Failed to update.");
    }
  }, [isErrorUpdate, errorUpdate]);

  const handleSave = async (values: Partial<UserM>) => {
    await updateUser(values)
      .unwrap()
      .catch(() => {});
  };

  const handleProfilePictureUpload = async (file: any, _: any) => {
    if (!authToken) return;

    const uploadedFileURL = await uploadAvatar(file, authToken);
    if (uploadedFileURL) {
      handleSave({
        profile_picture_url: uploadedFileURL,
      });
    }
  };

  const handleProfilePictureDelete = async () => {
    handleSave({
      profile_picture_url: "",
    });
  };

  if (loading) return null;

  const initialValues = {
    firstName: user?.first_name,
    lastName: user?.last_name,
    bio: user?.bio,
  };

  return (
    <>
      <PageHeading heading="Profile" />
      <TemplateContainer>
        <div className="flex items-center gap-4">
          <Avatar
            round
            color="lightgray"
            src={user?.profile_picture_url || ""}
            size="80px"
          />
          <Button
            variant="transparent-primary"
            onClick={() => setEditPhoto(true)}
          >
            Change
          </Button>
        </div>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={schema}
          onSubmit={() => {}}
        >
          {(props) => {
            const { values, setFieldValue } = props;
            const enableSave =
              initialValues.firstName !== values.firstName ||
              initialValues.lastName !== values.lastName ||
              initialValues.bio !== values.bio;
            return (
              <Form className="mt-4 flex flex-col items-start gap-6 border border-transparent max-w-sm">
                <div className="flex gap-6">
                  <Input
                    id="firstName"
                    label="firstName"
                    name="firstName"
                    type="text"
                    value={values.firstName}
                    onChange={(e) => setFieldValue("firstName", e.target.value)}
                  />
                  <Input
                    id="lastName"
                    label="lastName"
                    name="lastName"
                    type="text"
                    value={values.lastName}
                    onChange={(e) => setFieldValue("lastName", e.target.value)}
                  />
                </div>
                <Input
                  id="bio"
                  label="bio"
                  name="bio"
                  type="text"
                  value={values.bio}
                  tooltip='This is your default bio that will be added to the bottom of your articles in the "Article by" section. This can be overwritten in each template configuration.'
                  onChange={(e) => setFieldValue("bio", e.target.value)}
                />
                <div className="flex flex-col">
                  <div className="mb-1 flex justify-between uppercase text-xs tracking-wide font-medium leading-6 text-gray-900">
                    Email address
                  </div>
                  <div className="text-gray-500 text-sm">{user?.email}</div>
                </div>
                <Button
                  variant={enableSave ? "primary" : "disabled"}
                  loading={isLoadingUpadte}
                  onClick={() => {
                    handleSave({
                      first_name: values.firstName,
                      last_name: values.lastName,
                      bio: values.bio,
                    });
                  }}
                >
                  Save
                </Button>
              </Form>
            );
          }}
        </Formik>

        {editPhoto && (
          <MediaUploader
            heading="Upload Photo"
            src={user.profile_picture_url || ""}
            loading={isLoadingUpadte}
            onClose={() => setEditPhoto(false)}
            onUpload={handleProfilePictureUpload}
            onDelete={handleProfilePictureDelete}
          />
        )}
      </TemplateContainer>
    </>
  );
};

export default User;
