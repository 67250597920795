import React from "react";
import TemplateContainer from "../common/TemplateContainer";
import ArticleType from "./ArticleType";
import EmbedVideoToggle from "./EmbedVideoToggle";
import IncludeFAQToggle from "./IncludeFAQToggle";
import IncludeImageToggle from "./IncludeImageToggle";
import MediaSetup from "./MediaSetup/MediaSetup";
import PointOfView from "./PointOfView";
import SourceSelector from "./SourceSelector";
import TemplateName from "./TemplateName";

const Setup = () => {
  return (
    <div className="animate-fadeIn space-y-6">
      <TemplateContainer>
        <TemplateName />
      </TemplateContainer>

      <TemplateContainer className="space-y-6">
        <SourceSelector />
        <MediaSetup />
      </TemplateContainer>

      <TemplateContainer className="space-y-6">
        <IncludeImageToggle />
        <EmbedVideoToggle />
        <IncludeFAQToggle />
        <ArticleType />
        <PointOfView />
      </TemplateContainer>
    </div>
  );
};

export default Setup;
